// therapy dont show previous selection of meds screen 
// auto skip

import React, { Component } from 'react';
import Form from "@rjsf/core";
import 'bootstrap/dist/css/bootstrap.css';
import '../Questionnaire.css';
import { Confirm } from "react-async-popup";


const QTypes = {
    multiAnswer:'multiAnswer',
    //subMultiAnswer:'subMultiAnswer',
    medsBinary:'medsBinary',
    treatmentBinary:'treatmentBinary',
    severity:'severity',
    meditationYears:'meditationYears',
    meditationMinutes:'meditationMinutes'
}
const DEFAULT_TIME_TO_ANSWER = 100

class FormSelect extends Component {
    
    constructor(props) {
        super();
    
        this.state = {
            formData:undefined
        }
    }

    componentDidMount(){
        console.log('FormSelect : componentDidMount ')
        this.resetAutoSkip() 
    }

    componentWillUnmount(){
        console.log('FormSelect : componentWillUnmount clearingautoskip')

        clearTimeout(this.submitDelay) 
    }

    resetAutoSkip(){
        console.log('FormSelect :resetAutoSkip')

        clearTimeout(this.submitDelay) 
        this.submitDelay = setTimeout( () => {this.handleOnComplete()},
            (this.props.screen.timeToAnswer ? this.props.screen.timeToAnswer : DEFAULT_TIME_TO_ANSWER) * 1000 
        );
    }

    handleOnComplete(){
        this.resetAutoSkip()
        // filter corelating items:
        let selected = this.state.formData ? this.state.formData : undefined
        this.setState({formData:null})
        this.props.onTimeElapsed(selected)   
    }


    handleOnChange = event => {
        console.log('event:',event)
        let formData = event.formData

        this.setState({formData})
    };

    render(){
       
        return(
            <Form   schema={this.props.screen.schema}
                    uiSchema={this.props.screen.uiSchema}
                    onSubmit= { () => {this.handleOnComplete()}}
                    formData={this.state.formData}
                    onChange={e => this.handleOnChange(e)}
            >
                <button onClick={() => {this.handleOnComplete()}} type="button" className="btn btn-outline-dark btn-lg">{this.props.continueButton}</button>
                
            </Form>
            )
    }
} 
class FormMultiSelect extends Component {
    
    constructor(props) {
        super();
    
        this.state = {
            formData:[]
        }
    }


    componentDidMount(){
        console.log('FormMultiSelect : componentDidMount ')
        this.resetAutoSkip() 
    }

    componentWillUnmount(){
        console.log('FormMultiSelect : componentWillUnmount clearingautoskip')

        clearTimeout(this.submitDelay) 
    }

    resetAutoSkip(){
        console.log('FormMultiSelect :resetAutoSkip')

        clearTimeout(this.submitDelay) 
        this.submitDelay = setTimeout( () => {this.handleOnComplete()},
            (this.props.screen.timeToAnswer ? this.props.screen.timeToAnswer : DEFAULT_TIME_TO_ANSWER) * 1000 
        );
    }

    async handleOnComplete(){
        
        
        // filter corelating items:
        let items = this.props.screen.itemOptions.filter((item)=>this.state.formData.includes(item.label))
        
        if(items.length == 0){
            clearTimeout(this.submitDelay) 
            // props.texts.multiAnswer.confirmNoDiagnosis"לא בחרת אבחנה ,האם אתה בטוח שברצונך להמשיך?"
            const { show } = await Confirm.new({
                content: this.props.confirmNoDiagnosisText,
                popupStyle: {
                  textAlign: "right",
                  direction: "rtl",
                  borderWidth: 1
                },
                okText: this.props.confirmNoDiagnosisContinueButton,
                cancelText: this.props.confirmNoDiagnosisCancelButton,
                maskClosable: true
              });
        
            const uResponse = await show();
        
            if (uResponse) {
                // confirm
                this.resetAutoSkip() 
                this.props.onTimeElapsed(items)

            } else {
                // cancel:
                this.resetAutoSkip() 
            }
        }
        else{
            this.resetAutoSkip() 
            this.props.onTimeElapsed(items)

        }     

        
    }

    handleOnChange = event => {

        let formData = event.formData

        // None was selected ?
        if( !this.state.formData.includes(this.props.screen.selectNonLabel) && 
            formData.includes(this.props.screen.selectNonLabel)){

            // remove all existing selections:
            formData = [this.props.screen.selectNonLabel]
        }
        else{
            // remove 'None' from existing selections:
            formData = formData.filter( label => label !== this.props.screen.selectNonLabel); 
        }

        this.setState({formData})
    };

    render(){
       
        return(  
                <Form   schema={this.props.screen.schema}
                        uiSchema={this.props.screen.uiSchema}
                        onSubmit= { () => {this.handleOnComplete()}}
                        formData={this.state.formData}
                        onChange={e => this.handleOnChange(e)}
                
                >
                    <button onClick={() => {this.handleOnComplete()}} type="button" className="btn btn-outline-dark btn-lg">{this.props.continueButton}</button>
                    
                </Form>
            )
    }

} 

class Questionnaire extends Component {

    constructor(props) {
        super();
        this.state = {
            questionType: (props.askMeditationQuestion) ?  QTypes.meditationYears : QTypes.multiAnswer
        }

        // prepare screens:
        this.screens = this.createScreens(props)
    }

    createScreens(props){
        //title={this.props.texts.multiAnswer.title}
        //instruction={this.props.texts.multiAnswer.instruction}
        let digOptions = props.texts.multiAnswer.options
        //continueButton={this.props.texts.multiAnswer.continueButton}
        //TBD:answerPlaceholder = {this.props.texts.multiAnswer.answerPlaceholder}
        // dignoses:
        this.OPTIONS = []
        
        // options:
        this.itemOptions = digOptions.map((item) => {
            this.OPTIONS.push(item.dignosis)
            if(item.index=="-1"){
                this.selectNonLabel = item.dignosis
            }
            return {
                value:item.index,
                label:item.dignosis,
                subMultiAnswer:item.subMultiAnswer, 
            }
        })
        let dignosesScreen = {
            schema:{
                "title":props.texts.multiAnswer.title,
                "description":props.texts.multiAnswer.instruction,
                "type": "array",
                "items": {
                  "type": "string",
                  "enum": this.OPTIONS
                },
                "uniqueItems": true
            },
            uiSchema:{
         
                "ui:widget": "checkboxes",
                "description": {
                    "classNames": "form-question-title"
                }
            },
            itemOptions:this.itemOptions,
            selectNonLabel:this.selectNonLabel,
            timeToAnswer:props.timeToAnswer

        }

        // Severity Screen:

        this.severityOPTIONS = []
        props.texts.severity.options.forEach((item) => {
            this.severityOPTIONS.push(item.dignosis)            
        })

        let severityScreen  = {
            schema:{
                "title": props.texts.severity.title,
                "description": props.texts.severity.instruction,
                "type": "string",
                "enum": this.severityOPTIONS
            },
            uiSchema:{
                "ui:widget": "radio" 
            },
            timeToAnswer:props.timeToAnswer
  
        }

        let therapyScreen  = {
            schema:{
                "description":props.texts.treatment.title,
       
                "type": "string",
                "title": props.texts.treatment.instruction,
                "enum": props.texts.treatment.options
                  
                
            },
            uiSchema:{
                    "ui:widget": "radio"
            },  
            timeToAnswer:props.timeToAnswer
        }

        let medsScreen  = {
            schema:{
                "title":props.texts.meds.title,
                "type": "string",
                "description": this.getMedsInstruction(),
                "enum": props.texts.meds.options
            },
            uiSchema:{
                    "ui:widget": "radio"
            },
            timeToAnswer:props.timeToAnswer 
        }

        // meditationYears:
        this.meditationYearsOPTIONS = []
        props.texts.meditationYears.options.forEach((item) => {
            this.meditationYearsOPTIONS.push(item.dignosis)            
        })

        let meditationYearsScreen  = {
            schema:{
                "title": props.texts.meditationYears.title,
                "description": props.texts.meditationYears.instruction,
                "type": "string",
                "enum": this.meditationYearsOPTIONS
            },
            uiSchema:{
                "ui:widget": "radio" 
            },
            timeToAnswer:props.timeToAnswer
  
        }

        this.meditationMinutesOPTIONS = [...Array(60).keys()]
        
        let meditationMinutesScreen  = {
            schema:{
                "title": props.texts.meditationMinutes.title,
                "description": props.texts.meditationMinutes.instruction,
                "type": "object",
                "properties":{
                    "hours":{
                        "enum": this.meditationMinutesOPTIONS,
                        "description":props.texts.meditationMinutes.labelHours
                    },
                    "minutes":{
                        "enum": this.meditationMinutesOPTIONS,
                        "description":props.texts.meditationMinutes.labelMinutes
                    }
                }
            },
            uiSchema:{
                "minutes":{
                    "ui:widget": "select",
                    "ui:title": " " 

                },
                "hours":{
                    "ui:widget": "select" ,
                    "ui:title": " "

                }
            },
            timeToAnswer:props.timeToAnswer
  
        }
        return [dignosesScreen,medsScreen,therapyScreen,severityScreen,meditationYearsScreen,meditationMinutesScreen]

    }

    severityMultiCompleted(data){
        // user didnt choose (and pressed continue) - so End.

        // translate to number:
        let severity = undefined
        this.props.texts.severity.options.forEach((item) => {
            if(item.dignosis == data){
                severity = item.index
            }
              
        })
        
        this.setState({
            severityAnswer:severity,
            questionType : QTypes.severity,

        }  ,this.onComplete) 
    }

    meditationYearsMultiCompleted(data){

        // translate to number:
        let meditationYears = undefined
        this.props.texts.meditationYears.options.forEach((item) => {
            if(item.dignosis == data){
                meditationYears = item.normelizedValue
            }
              
        })

        this.setState({
            meditationYearsAnswer:meditationYears,
            questionType : QTypes.meditationMinutes,
        }   ) 
       
    }

    meditationMinutesMultiCompleted(data){
        // translate to number:
        let meditationMinutes = undefined

        if(data && (data.hours || data.hours==0)){
            meditationMinutes = data.hours * 60
        }
        if(data && (data.minutes || data.minutes==0)){
            if(!meditationMinutes){
                meditationMinutes = 0
            }
            meditationMinutes = meditationMinutes + data.minutes
        }
        console.log('meditationMinutesMultiCompleted: meditationMinutes:',meditationMinutes)


        this.setState({
            questionType : QTypes.multiAnswer,

            meditationMinutesAnswer:meditationMinutes
        } )  //,this.onComplete) 
       
    }

    dignosisMultiCompleted(data){
        console.log('dignosisMultiCompleted: answer:',data)
        if( !data || 
            !data.length ||
            data[0].value == -1) {
            // user didnt choose (and pressed continue) - so End.
            this.setState({
                multiAnswer:data,
    
            }  ,this.onComplete) 
            
        }
        else{
            let questionType = QTypes.medsBinary;
            // TBD: check if  subQuestion option was selected and set state :
            console.log('something was selected : data',data)
            // subQuestion ?
            let subMultiAnswer = null;
            for (let index = 0; index < data.length; index++) {
                const item = data[index];
                if(item.subMultiAnswer){
                    subMultiAnswer = item.subMultiAnswer
                    break;
                }
            }
            if(subMultiAnswer){
                console.log('subMultiAnswer',subMultiAnswer)

                this.subMultiAnswer = subMultiAnswer
                questionType = QTypes.subMultiAnswer
            }

            this.setState({
                questionType:questionType,
                multiAnswer:data
            })
        }   
    }

    onComplete(){
        if(!this.state.multiAnswer){
            this.props.onDone(null)
            return
        }

        let diagnoses = this.state.multiAnswer.map((item)=>item.label)
        let meds = this.state.medsBinaryAnswer
        let treatment = this.state.treatmentBinaryAnswer
        let severity = this.state.severityAnswer
        let meditationYears = this.state.meditationYearsAnswer
        let meditationMinutes = this.state.meditationMinutesAnswer

        
        // complete :
        let result = {
            diagnoses,
            meds,
            treatment,
            severity,
            meditationYears,
            meditationMinutes,
        }

        if(this.state.subMultiAnswer && this.state.subMultiAnswer.length){
            result.subMultiAnswer = this.state.subMultiAnswer.map((item)=>item.label)
        }

        this.props.onDone(result)
    }

    medsBinaryCompleted(data){
        this.setState({
            questionType : QTypes.treatmentBinary,
            medsBinaryAnswer : data
        })
    }

    treatmentBinaryCompleted(data){
        this.setState({
            questionType : QTypes.severity,
            treatmentBinaryAnswer : data
        })
    }

    getMedsInstruction(){
        // sanity :
        if(!this.state.multiAnswer || !this.state.multiAnswer.length){
            return ""
        }

        // get smallest index of selected :
        let sortedAr = this.state.multiAnswer.sort((a,b) => {
            let aVal = parseInt(a.value)
            let bVal = parseInt(b.value)
            if (aVal < bVal) {
              return -1;
            }
            if (aVal > bVal) {
              return 1;
            }
            // a must be equal to b
            return 0;
          }); 
                
        const smallestIndex = sortedAr[0].value
        // find instruction:
        let item = this.props.texts.multiAnswer.options.filter(item => item.index == smallestIndex)

        return item[0].medsInstruction
    }
 
    render() {
        switch (this.state.questionType) {
            
            case QTypes.multiAnswer:
                return (
                    <div>
                        
                        <FormMultiSelect 
                            screen = {this.screens[0]}
                            continueButton={this.props.texts.multiAnswer.continueButton}
                            onTimeElapsed ={(answer)=>this.dignosisMultiCompleted(answer)}
                            timeToAnswer={this.props.timeToAnswer}
                            rtl = {this.props.rtl}
                            confirmNoDiagnosisText = {this.props.texts.multiAnswer.confirmNoDiagnosis}
                            confirmNoDiagnosisContinueButton = {this.props.texts.multiAnswer.confirmNoDiagnosisContinueButton }
                            confirmNoDiagnosisCancelButton =  {this.props.texts.multiAnswer.confirmNoDiagnosisCancelButton} 
                            />
                    </div>
                ) 

 
            case QTypes.medsBinary:
                this.screens[1].schema.description = this.getMedsInstruction()
                return (
                    <div>
                         <FormSelect 
                            screen = {this.screens[1]}
                            continueButton={this.props.texts.meds.continueButton}
                            onTimeElapsed ={(answer)=>this.medsBinaryCompleted(answer)}
                            timeToAnswer={this.props.timeToAnswer}

                            rtl = {this.props.rtl}
                           
                            />
                    </div>
                )
                
            case QTypes.treatmentBinary:
                return (
                    <div>
                        <FormSelect 
                            screen = {this.screens[2]}
                            continueButton={this.props.texts.treatment.continueButton}
                            onTimeElapsed ={(answer)=>this.treatmentBinaryCompleted(answer)}
                            timeToAnswer={this.props.timeToAnswer}

                            rtl = {this.props.rtl}
                            
                            />
                    </div>
                )

            case QTypes.severity:
                return (
                    <div>
                        <FormSelect 
                            screen = {this.screens[3]}
                            continueButton={this.props.texts.severity.continueButton}
                            onTimeElapsed ={(answer)=>this.severityMultiCompleted(answer)}
                            timeToAnswer={this.props.timeToAnswer}

                            rtl = {this.props.rtl}
                           
                            />
                    </div>
                )
            case QTypes.meditationYears:
                return (
                    <div>
                        <FormSelect 
                            screen = {this.screens[4]}
                            continueButton={this.props.texts.meditationYears.continueButton}
                            onTimeElapsed ={(answer)=>this.meditationYearsMultiCompleted(answer)}
                            timeToAnswer={this.props.timeToAnswer}

                            rtl = {this.props.rtl}
                            
                            />
                    </div>
                ) 
            case QTypes.meditationMinutes:
                return (
                    <div>
                        <FormSelect 
                            screen = {this.screens[5]}
                            continueButton={this.props.texts.meditationMinutes.continueButton}
                            onTimeElapsed ={(answer)=>this.meditationMinutesMultiCompleted(answer)}
                            timeToAnswer={this.props.timeToAnswer}

                            rtl = {this.props.rtl}
                            
                            />
                    </div>
                )    
            default:
                break;
        }
    }
}

export default Questionnaire;
