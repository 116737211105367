import React, { Component } from 'react';

class NoiseDetector extends Component {

    render() {
      return (
        <div>
            <h1 className="title" >{this.props.texts.title}</h1>
        </div>
      )
    }
}

export default NoiseDetector;