import React, { Component } from 'react';
import { Messages } from '../const'
import {CopyToClipboard} from 'react-copy-to-clipboard';

class LeaveBrowserMessage extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            value:window.location,
            copied:false
        }
    }

    render() {
      return (
        <div style= {{margin:'50px'}}>

             <h1 className="title" >{this.props.texts.title}</h1>
              <p> {this.props.texts.instruction}</p>

              <hr/>

              <CopyToClipboard text={this.state.value}
                onCopy={() => {console.log('copying'); this.setState({copied: true})}}>
                <button className="start-button">{this.props.texts.copyButton}</button>
              </CopyToClipboard>

        </div>
      )
    }
}

export default LeaveBrowserMessage;