import React, { Component } from 'react';
import { Messages } from '../const';
const SECOND = 1000
class UserEmailQuestion extends Component {

  constructor(){
    super();
    this.state = {
      email : '',
    }
  }

  componentWillUnmount(){
    clearTimeout(this.submitDelayTimout)
  }

  componentDidMount(){

    console.log('RecordingTimeQuestion:submitDelay:',this.props.submitDelay)
    if(this.props.submitDelay){    
      this.submitDelayTimout = setTimeout( () => {this.props.onCompletion(this.state.email) }, this.props.submitDelay * SECOND); 
    }
    /*
    // Restricts input for the given textbox to the given inputFilter.
    function setInputFilter(textbox, inputFilter) {
      ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
        textbox.addEventListener(event, function() {
          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.value = this.oldValue;
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
          }
        });
      });
    }

    setInputFilter(document.getElementById("email"), function(value) {
      return /^\d*\.?\d*$/.test(value);
    });
    */
  }

  updateSecondsInput(email){
    this.setState({email : email})
  }

  onDonePressed(){
    
    this.props.onCompletion(this.state.email)
  
    /*
    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    // No need to validate:
    if(validateEmail(this.state.email)){
        this.props.onCompletion(this.state.email)
    }
    else{
        alert(Messages.InvalidEmailInput)
    }
    */
  }

  render() {
    return (
      <div>
          <h1 className="title" >{this.props.texts.title}</h1>
          <h2 className = "instruction" >{this.props.texts.instruction}</h2>
          <div className = "timeInputContainer">
              <input  id='email' 
                      className = "userEmailInput" 
                      onChange={(event) => this.updateSecondsInput(event.target.value)} 
                      type="text" 
                      placeholder={this.props.texts.secondsPlaceHolder} 
                      maxLength="200" 
                      name="email" 
                      value = {this.email}/>
          </div>
          <h1 className="start-button" onClick={() => {this.onDonePressed() }}>{this.props.texts.doneButton}</h1>

      </div>
    )
  }
}

export default UserEmailQuestion;
