import React, { Component } from 'react';

class Record extends Component {

    
    render() {
      return (
        <div className="recording-title" >
            <h1 className="recording-title"> {this.props.texts.title}</h1>

            <img src="https://mturk-manage-2018-04-16.s3-eu-west-1.amazonaws.com/recIcon.png" />
        </div>
      )
    }
}

export default Record;