import React, { Component } from 'react';
const SECOND = 1000
class ExtraDataQuestion extends Component {

  constructor(){
    super();
    this.state = {
      item1 : '',
      item2 : '',
    }
  }

  componentDidMount(){
    console.log('submitDelay:',this.props.submitDelay)
    this.submitDelayTimout = setTimeout( () => {this.handleDonePressed()}, this.props.submitDelay * SECOND); 
  }

  componentWillUnmount(){
    clearTimeout(this.submitDelayTimout)
  }

  handleDonePressed(){
    let extraData = this.state.item1+','+
                    this.state.item2+','+
                    this.state.item3+','+
                    this.state.item4+','+
                    this.state.item5
    console.log('handleDonePressed: extraData:',extraData)
    this.props.onCompletion(extraData)
  }

  updateItem1Input(item1){
    this.setState({item1 : item1})
  }
  updateItem2Input(item2){
    this.setState({item2 : item2})
  }
  updateItem3Input(item3){
    this.setState({item3 : item3})
  }
  updateItem4Input(item4){
    this.setState({item4 : item4})
  }
  updateItem5Input(item5){
    this.setState({item5 : item5})
  }


  render() {
    return (
      <div>
          <h1 className="title" >{this.props.texts.title}</h1>
          <h2 className = "instruction" >{this.props.texts.instruction}</h2>
          <div className = "timeInputContainer">
              <input className = "timeInput" onChange={(event) => this.updateItem1Input(event.target.value)} type="text" placeholder={this.props.texts.itemPlaceHolder} maxLength="80" name="item1" value = {this.item1} />  
              <input className = "timeInput" onChange={(event) => this.updateItem2Input(event.target.value)} type="text" placeholder={this.props.texts.itemPlaceHolder}  maxLength="80" name="item2" value = {this.item2}/>
              <input className = "timeInput" onChange={(event) => this.updateItem3Input(event.target.value)} type="text" placeholder={this.props.texts.itemPlaceHolder}  maxLength="80" name="item3" value = {this.item1} />  
              <input className = "timeInput" onChange={(event) => this.updateItem4Input(event.target.value)} type="text" placeholder={this.props.texts.itemPlaceHolder}  maxLength="80" name="item4" value = {this.item2}/>
              <input className = "timeInput" onChange={(event) => this.updateItem5Input(event.target.value)} type="text" placeholder={this.props.texts.itemPlaceHolder}  maxLength="80" name="item5" value = {this.item1} />  
          </div>
      </div>
    )
  }
}

export default ExtraDataQuestion;

//           <h1 className="start-button" onClick={() =>  {this.handleDonePressed()} }>Done</h1>
