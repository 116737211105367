import React, { Component } from 'react';

class Instruction extends Component {
    
    render() {
      return (
        <div>
            <h1 className="title" >{this.props.texts.title}</h1>
            <p className = "instruction" > {this.props.texts.category}</p>
        </div>
      )
    }
}

export default Instruction;