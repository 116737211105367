import {StackdriverErrorReporter} from 'stackdriver-errors-js'
import * as StackTrace from 'stacktrace-js';
import { updateEntryItem } from './utils';
import platform from './platform';

window.StackTrace = StackTrace

class ErrorReporter {

    init(enabled,service,params){
        this.enabled = enabled;  

        if(enabled){
            this.stackdriverErrorReporter = new StackdriverErrorReporter();
            this.stackdriverErrorReporter.start({
                // TBD: move  keys to conf which is gitignored
              key: 'AIzaSyAkbOmPB53U1nNPXxbV9nws_hx51wPBdxI',
              projectId: 'able-groove-224509',
              service: service,                    // (optional)
              //version: '<my-service-version>',            // (optional)
              // reportUncaughtExceptions: false          // (optional) Set to false to stop reporting unhandled exceptions.
              // reportUnhandledPromiseRejections: false  // (optional) Set to false to stop reporting unhandled promise rejections.
              // disabled: true                           // (optional) Set to true to not report errors when calling report(), this can be used when developing locally.
              // context: {user: this.params.workerId}                 // (optional) You can set the user later using setUser()
            }); 
        }

        this.context = params
    }

    setContext(context){
        this.context = context
    }

    report(message,type,workerId,cb){

        var item =     {
            errorMessage: message, 
            errorType: type,
            lastAppState:platform.appState     
        }

        // add fbRedirect column: 
        if(platform && platform.urlParams && platform.urlParams.fbclid){
            item.fbclid = platform.urlParams.fbclid
        }

        // update Entry:
        if(this.enabled && !type.includes( 'WorkerAlreadyParticipated') ){
            let params = this.context  
            let entryId = params.workerId + '_' + params.hitId

            updateEntryItem(entryId,item, (error,res) => {
            
                console.log('putEntry result:',res)
                if(error){
                    // coudnt updateEntry so we report that too 
                    var report = JSON.stringify({
                        type:'updateEntryItemError',
                        message:'Error, reporter failed to updateEntryItem.',
                        workerId:workerId,
                        context:this.context,
                    })
                    this.sendReport(report)
                }

                //  report issue:
                var report = JSON.stringify({
                    type,
                    message,
                    workerId,
                    context:this.context,
                })
                this.sendReport(report) 
                
                // CB:
                if(cb){
                    cb()
                }
            })
        }
        else{
            //  report issue:
            var report = JSON.stringify({
                type,
                message,
                workerId,
                context:this.context,
            })
            this.sendReport(report)
            
            // CB:
            if(cb){
                cb()
            }
        }
    }

    sendReport(report){
        if(this.enabled){
            console.error('Stack driver - sendReport: ',report);
            this.stackdriverErrorReporter.report(report)
        }
        else{
            console.error(report);
        }
    }
}

let errorReporter = new ErrorReporter()

export default errorReporter;

