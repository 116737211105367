import { PlatformName,ObjectTypes ,Endpoints} from './const'
import {  osVersion,osName,fullBrowserVersion,browserVersion,browserName,mobileVendor,mobileModel,engineName,engineVersion,getUA,deviceType ,isMobile} from 'react-device-detect';
import axios  from 'axios';


export var getUrlVars = function(url) {
  var vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}
export var getUrlParam = function(url, parameter, defaultvalue){
  var urlparameter = defaultvalue;
  url = decodeURIComponent(url)
  if(url.indexOf(parameter) > -1){
      urlparameter = getUrlVars(url)[parameter];
      }
  return urlparameter;
}

export var getParam = function(object, parameter, defaultvalue){
  var val = defaultvalue;
  if(object[parameter]){
    val = object[parameter];
  }
  return val;
}


export var getDeviceInfo = function(md) {

  var str = '-';
  str += ( md.mobile() ? 'mobile: '+ md.mobile() + '\n' : '') 
  str += ( md.phone() ? 'phone: '+ md.phone() + '\n' : '') 
  str += ( md.userAgent() ? 'userAgent: '+ md.userAgent() + '\n' : '')
  str += ( md.os() ? 'os: '+ md.os() + '\n' : '') 

  return str
}

export var createRandomFilename = function (){
    // TBD: consider using uuid npm package instead
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  
    for (var i = 0; i < 30; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;

  }

  export var updateAssignmentItem = function(assignmentId,updateItem,cb){

    let data = {
      objectType: ObjectTypes.assignment,
      itemKey: assignmentId,
      item: updateItem,
    }

    axios({
      method: 'post',
      url: Endpoints.updateItem,
      data: data
    })
    .then( (response)=> {
      console.log('updateAssignmentItem:',response);
      if(cb){
        cb(null,response)
      }
    })
    .catch( (error) => {
      
      if(cb){
        cb(error,null)
      }
    });
  }


  export var updateEntryItem = function(workerId,item,cb){
    item.osVersion = osVersion
    item.osName = osName
    item.fullBrowserVersion = fullBrowserVersion
    item.browserVersion = browserVersion
    item.browserName = browserName
    item.mobileVendor = mobileVendor
    item.mobileModel =mobileModel
    item.engineName = engineName
    item.engineVersion = engineVersion
    item.getUA = getUA
    item.deviceType = deviceType
    item.isMobile = isMobile
    
    var url = window.location.href;
    item.isSandbox=getUrlParam(url,'IsSandbox','no')

    let data = {
      objectType:ObjectTypes.entry,
      itemKey:workerId,
      item:item,
    }

    axios({
      method: 'post',
      url: Endpoints.updateItem,
      data: data,
    })
    .then( (response) => {
      if(cb){
        cb(null,response)
      }
      else{
        console.log('updateEntryItem cb is null')
      }
    })
    .catch( (error) => {
      if(cb){
        cb(error,null)
      }
      else{
        console.log('updateEntryItem:Error, cb is null')
      }

    });
  }


  export var openInBrowser =function (target, browserScheme) {
    var ifc = document.createElement("div");
    ifc.innerHTML = `<iframe src='${browserScheme}${target}' style='width:0;height:0;border:0; border:none;visibility: hidden;'></iframe>`;
    document.body.appendChild(ifc);
}

export var isInApp = function(appSpecificUserAgents) {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    for (var i = 0; i <= appSpecificUserAgents.length; i++) {
        if (userAgent.indexOf(appSpecificUserAgents[i]) > -1) return true;

    }
}

export var removeURLParameter = function(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');   
  if (urlparts.length >= 2) {

      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (var i = pars.length; i-- > 0;) {    
          //idiom for string.startsWith
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
              pars.splice(i, 1);
          }
      }

      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

