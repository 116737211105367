import Matter from 'matter-js'

const WIDTH = window.innerWidth - (0.15 * window.innerWidth) 
const HEIGHT = window.innerHeight - (0.3 * window.innerHeight)

var playground = playground || {};

// define our categories (as bit fields, there are up to 32 available)
var defaultCategory = 0x0001,
    redCategory = 0x0002,
    greenCategory = 0x0004,
    blueCategory = 0x0008;

var redColor = '#C44D58',
    blueColor = '#4ECDC4',
    greenColor = '#C7F464';

playground.airFriction = function(  element,
                                    clickBallCB,
                                    ballsCount,
                                    ballSelectedColor,
                                    ballsSpeed,
                                    ballsCollisionEnabled,
                                    ballsSpeedIsConstant) {
    console.log(element)

    
    var Engine = Matter.Engine,
        Render = Matter.Render,
        Runner = Matter.Runner,
        MouseConstraint = Matter.MouseConstraint,
        Mouse = Matter.Mouse,
        World = Matter.World,
        Bodies = Matter.Bodies,
        Body = Matter.Body,
        Composite = Matter.Composite,
        Constraint = Matter.Constraint,
        Events = Matter.Events;

    // create engine
    var engine = Engine.create(),
        world = engine.world;

    // world props:
    world.gravity = {x:0,y:0}

    // create renderer
    var render = Render.create({
        element: element,
        engine: engine,
        options: {
            width: WIDTH,
            height:  HEIGHT,
            showVelocity: false,
            background: '#f7e7dd',
            //showIds:true,
            wireframes: false
        }
    });

    Render.run(render);

    // create runner
    var runner = Runner.create();
    Runner.run(runner, engine);

    // add bodies

    var category = redCategory,
        color = redColor;

    let ballsComposite = Composite.create()

    // randomize selected ball:
    const randSelectedIndex = Math.floor(Math.random()*ballsCount) + 1
    console.log('randSelectedIndex:',randSelectedIndex)
    console.log('ballsCount:',ballsCount)

    // create Balls:
    let balls = []
    for (let index = 0; index < ballsCount; index++) {
        let randX = Math.floor((Math.random()*WIDTH/2) - 100) + 100
        //let randY = Math.floor( (Math.random()*((HEIGHT) )-(HEIGHT/2)+1)+(HEIGHT/2) ) -100;
        //let randY = Math.floor(Math.random() * (Math.floor(HEIGHT-100) - Math.floor(HEIGHT/2) + 1)) + Math.floor(HEIGHT/2);
        let randY = Math.floor( HEIGHT/2 ) -100
        console.log('randX:',randX)
        console.log('randY:',randY)
        console.log('index:',index)        
        balls.push(Bodies.circle( randX,randY, 15,  { 
            label:(randSelectedIndex == index+1) ? 'x' : '',
            frictionAir: 0.00,
            restitution:1,
            collisionFilter: {
                group: ballsCollisionEnabled ? 0 : -1  
            },
            render: {
                fillStyle: (randSelectedIndex == index+1) ? ballSelectedColor : redColor,

            } 
        }))
        
    }
    

    Composite.add(ballsComposite,balls)  

    const thickness = 20;
    World.add(world, [
        ballsComposite,
        // walls
        Bodies.rectangle(0, 0, WIDTH, thickness, { isStatic: true ,restitution:1}),
        Bodies.rectangle(0,0, thickness, HEIGHT, { isStatic: true,restitution:1 }),
        Bodies.rectangle(0,  HEIGHT/2 , WIDTH, thickness, { isStatic: true ,restitution:1}),
        Bodies.rectangle( WIDTH/2, HEIGHT/2 , thickness, HEIGHT, { isStatic: true ,restitution:1})
    ]);

    // is speed constant ?  
    if(ballsSpeedIsConstant){
        var baseSpeed = ballsSpeed;
        Events.on(engine, "beforeUpdate", (event) => {
            // adjust balls speed:
            for (var ball of event.source.world.composites[0].bodies) {
                if (baseSpeed == 0) baseSpeed = ball.speed;
                if (ball.speed != 0) {
                let speedMultiplier = baseSpeed / ball.speed // 11.241098900509593 == initial (starting) ball speed
                Body.setVelocity(
                    ball, {
                    x: ball.velocity.x * speedMultiplier,
                    y: ball.velocity.y * speedMultiplier
                    }
                );
                }
            }
        });
    }

    // add mouse control
    var mouse = Mouse.create(render.canvas),
        mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false
                }
            }
        });


    World.add(world, mouseConstraint);

    // keep the mouse in sync with rendering
    render.mouse = mouse;
    
    // disable mouse:
    mouseConstraint.collisionFilter.mask = 0 //defaultCategory | redCategory | blueCategory | greenCategory;

    // events:
    Events.on(mouseConstraint, 'mouseup', function(event) {
        var mousePosition = event.mouse.position;
        console.log('mouseup at ' + mousePosition.x + ' ' + mousePosition.y);
       
        console.log('event :' , event);
        var bodiesInvolved = Matter.Query.point(Composite.allBodies(ballsComposite), event.mouse.position)
        console.log('bodiesInvolved :' , bodiesInvolved);

        if(bodiesInvolved.length){
            var body = bodiesInvolved[bodiesInvolved.length-1];
            //if(!body.label == 'wall'){
                console.log('clicked on body id:',body.id) 
                if(body.label == 'x'){
                    clickBallCB(true)
                }
                else{
                    clickBallCB(false)
                }
                
            //}
        }
    }); 

    // fit the render viewport to the scene
    Render.lookAt(render, {
        min: { x: 0, y: 0 },
        max: { x: WIDTH/2, y: HEIGHT /2}
    });

    // methods:
    let moveBodiesRandomly = function(composite){
        let bodies = Composite.allBodies(composite)
        for (let index = 0; index < bodies.length; index++) {
            const body = bodies[index];
            var xRandSign = Math.random() < 0.5 ? -1 : 1;
            var yRandSign = Math.random() < 0.5 ? -1 : 1;
            Body.applyForce(body, body.position, {
                x: 0.001 * xRandSign * ballsSpeed ,
                y: 0.001 * yRandSign * ballsSpeed 
            })
        }

    }

    let stopBodies = function(composite){
        let bodies = Composite.allBodies(composite)
        for (let index = 0; index < bodies.length; index++) {
            const body = bodies[index];
            Body.setStatic(body, true);
            
             /*        
            var constraint = Constraint.create({
                pointA: {x:10,y:10},
                bodyB: body,
                stiffness:1,
                render:{visible:false}
            });
            var constraint2 = Constraint.create({
                pointA: {x:WIDTH,y:HEIGHT},
                bodyB: body,
                stiffness:1,

                render:{visible:false}
            });
            Composite.add(composite,[constraint,constraint2])
            */
        }
    }

    let releaseBodies = function(composite){
        let constraints = Composite.allConstraints(composite)

        Composite.remove(composite,constraints)
        
    }

    let changeColor = function(composite){
        let bodies = Composite.allBodies(composite)
        for (let index = 0; index < bodies.length; index++) {
            const body = bodies[index];
            //TBD: 
            body.render.fillStyle = redColor
        }
    }


    // context for MatterTools.Demo
    return {
        engine: engine,
        runner: runner,
        render: render,
        canvas: render.canvas,
        stop: function() {
            Matter.Render.stop(render);
            Matter.Runner.stop(runner);
        },
        move: function() {
            moveBodiesRandomly(ballsComposite);
        },
        stop: function() {
            stopBodies(ballsComposite)
        },
        release : function() {
            releaseBodies(ballsComposite)
        },
        changeDirection: function() {
            moveBodiesRandomly(ballsComposite)
        },
        changeColor: function() {
            changeColor(ballsComposite)
        }
        
    };
};

export default playground
