
export const AMARIL_SANDBOX_DIRECTORY_PREFIX = "Sandbox__"
export const ASSIGNMENT_ID_NOT_AVAILABLE = 'ASSIGNMENT_ID_NOT_AVAILABLE'

export const FlowStates = {
    initial:'initial',
    handleInAppBrowser:'handleInAppBrowser',
    gotStream:'gotStream',
    gotSignedUrl:'gotSignedUrl',
    quiz:'quiz',
    welcome:'welcome',
    preWelcome:'preWelcome',
    welcomeInstruction:'welcomeInstruction',
    detectNoise:'detectNoise',
    preInstruction:'preInstruction',
    instruction:'instruction',
    record:'record',
    recordStop:'recordStop',
    workerIdQuestion:'workerIdQuestion',
    userDetails:'userDetails',
    timeQuestion:'timeQuestion',
    extraDataQuestion:'extraDataQuestion',
    multiAnswerQuestion:'multiAnswerQuestion',
    userEmailQuestion:'userEmailQuestion',
    touchTask:'touchTask',
    done:'done',
  }

/*
export var Messages = {
    ConfirmStart:"You have only 1 attempt, are you sure you want to Start?",
    RequireAcceptHit:"You must accept this HIT before you may continue.",
    notOnMobile:"In order to complete this assignment you must open the page on a mobile device.",
    RequireMicEnable:"Microphone is blocked, Please enable the Microphone.",
    MicNotFound:"Microphone not found, you must connect a microphone in order to participate.",
    BrowserNotCompatible:"Your browser is not compatible with the app, please upgrade.",
    LoadingError:"A problem occured when loading the Application, try to refresh this page. (are you connected to the internet?)",
    EncoderInitFailed:"An error occured when initilizing the Application, please try to refresh this page.",
    EncodingFailed:"An error occured while recording.",
    WorkerAlreadyParticipated:"You may participate in only one of Anat maril's experiments, and you have already participated.",
    WorkerLanguageRequirement:"You can not participate, since you dont meet the Language requirement of this expermient.",
    DecreaseBackgroundNoise:"You can not start the experiment since Background noises are too loud, please move to a quiet envirounment and press ok.",
    NoiseDetectionFailed:"you can not proceed to record, since the envirounment noises are too loud.",
    RequireMicWarning:"you will now be prompted to allow use of the Microphone, please allow inroder to proceed.",
    QuizFailed:"you may not proceed since you have not passed the quiz.",

}
*/
export var Messages = {}
// TBD: load defualts when messages is null
export function setMessages(messages){
  Messages = messages
}

export const Bool = {
  yes:'yes',
  no:'no',
}

export const PlatformTypes = {
  mturk:'mturk',
  ipanel:'ipanel',
  test:'test',
  free:'free',
  panelForAll:'panelForAll',
  prolific:'prolific',


}

export const Categories = {
  animals:'animals',
}

export const EncodingTypes = {
  flac:'flac',
  wav:'wav',
} 

export const Default = {
  recordingDuration: "60",
  delayDuration: "6",
  hitId: 'hitId',
  workerId: 'workerId',
  category: 'animals',
  devEnv: Bool.no,
  experimentName: AMARIL_SANDBOX_DIRECTORY_PREFIX+'test',
  askRecordingDuration: Bool.no,
  askExtraData:Bool.no,
  askMultiAnswerQuestion:Bool.no,
  askMeditationQuestion:Bool.no,
  askUserEmailQuestion:Bool.no,
  AskWorkerIdQuestion: Bool.no,
  multiAnswerTimeToAnswer:"90",
  enforceOnMobile: Bool.no,
  enforceOnDesktop: Bool.no,
  ageCutoff:"40",
  noiseDetectionDbThreshold:"20",
  noiseSamplingDuration:"4",
  askExtraData:Bool.no,
  isSandbox:Bool.no,
  configFilename: null,
  extraDataSubmitDelay:"30",
  userDetailsSubmitDelay:"60",
  timeQuestionSubmitDelay:"10",
  userEmailQuestionSubmitDelay:"60",
  checkIsUploadDoneDelay: "90",
  checkIsUploadDoneInterval: "10",
  checkIsUploadDoneTimeout: "120",
  quizPassThreshold:"2",
  quizTimeToAnswer:"15",
  platform:PlatformTypes.ipanel,
  language:'heb',
  enforceLanguage:Bool.no,
  // TBD: platform :  we cant control url params given by ipanel so thats the defualt
  encoding: EncodingTypes.wav,
  quizEnabled : Bool.no,
  // balls:
  ballsEnabled : Bool.no,
  ballsCount: 3,
  ballsPracticeDuration: 20,
  ballsSpeed: 4,
  ballRevealDuration: 15,
  ballSelectedColor: "4ECDC4",
  ballsCollisionEnabled : Bool.yes,
  ballsRecordingDelay: 7,
  ballsSpeedIsConstant: Bool.yes,
  category:"animals",
  enforceDeviceBlock:Bool.yes,
  
}

export let PlatformName  = Default.platform
export function setPlatformName(platformName){
  PlatformName = platformName
}

export const Endpoints = {
  submitAssignment:{
    sandbox:'https://workersandbox.mturk.com/mturk/externalSubmit',
    production:'https://www.mturk.com/mturk/externalSubmit'
  },
  getSignedURL: 'https://gz9mat81pd.execute-api.eu-west-1.amazonaws.com/dev/getSignedURL',
  workerExistsInProd :'https://europe-west1-able-groove-224509.cloudfunctions.net/amaril-exp-app-onload',
  putRecordingMeta:'https://c3zd5z06vk.execute-api.eu-west-1.amazonaws.com/default/amaril-recording-meta',
  blockWorker:'https://gv2cqqjhl6.execute-api.eu-west-1.amazonaws.com/default/amaril-block-worker',
  updateItem:'https://us-central1-able-groove-224509.cloudfunctions.net/amaril-update-item',
  getItem:'https://us-central1-able-groove-224509.cloudfunctions.net/wasAudioCreated',
}



export const ObjectTypes = {
  assignment:'assignment',
  entry:'entry',
  word:'word',
}

export const PlatformTables = {
  mturk:{
    assignments:'MturkAssignments',
    entries:'MturkEntries'
  },
  ipanel:{
    assignments:'AmarilAssignments',
    entries:'AmarilEntries'
  },
  test:{
    assignments:'MturkAssignments',
    entries:'MturkEntries'
  }
}

export const PlatformEndpoints = {
  mturk:{
    submitAssignment:{
      sandbox:'https://workersandbox.mturk.com/mturk/externalSubmit',
      production:'https://www.mturk.com/mturk/externalSubmit'
    },
  },
  ipanel:{
    submitAssignment:'https://us-central1-able-groove-224509.cloudfunctions.net/amaril-on-submit',
    redirect: 'https://www.ipanel.co.il/SurveyInterface/RedirectBackToSG.html'
  },
  panelForAll:{
    submitAssignment:'https://us-central1-able-groove-224509.cloudfunctions.net/amaril-on-submit',
    redirect: 'http://www.panel4all.co.il/survey_runtime/external_survey_status.php'
  },
  prolific:{
    submitAssignment:'https://us-central1-able-groove-224509.cloudfunctions.net/amaril-on-submit',
    redirect: 'https://app.prolific.co/submissions/complete'
  },
  test:{

  }
}

