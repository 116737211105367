import React, { Component } from 'react';
import Select from 'react-select';
const SECOND = 1000

class UserDetails extends Component {

    constructor(props){
      super()


      // set Age options:
      this.options = []
      for (let index = 18; index < 90; index++) {
        var element = {
           value: index, 
           label: (index+'') 
        }
        this.options.push(element) 
      }

      // set Gender options:
      let genders = props.texts.genderOptions
      this.genderOptions = []
      for (let index = 0;index < genders.length; index++) {
        var element = genders[index]
       this.genderOptions.push(element) 
      }

      // languages:
      this.languagesOptions = props.texts.languageOptions

      // levels:
      this.levelsOptions = props.texts.languageLevelOptions
  
      this.state = {
        gaveConsent:false,
        selectedOption: null,
        selectedGenderOption:null,
        languages:[{
          selectedLanguagesOption:null,
          selectedLevelOption:null
        }]
      }
    }

    componentDidMount(){
      console.log('submitDelay:',this.props.submitDelay)
      this.submitDelayTimout = setTimeout( () => {this.handleStartPressed()}, this.props.submitDelay * SECOND); 
    }
  
    componentWillUnmount(){
      clearTimeout(this.submitDelayTimout)
    }

    handleChange = (selectedOption) => {
      this.setState({ selectedOption });
    }

    

    handleGenderChange = (selectedGenderOption) => {
      this.setState({ selectedGenderOption });
    }
    handleLanguageChange = (selectedLanguagesOption,i) => {
        this.setState(state => {
          const languages = state.languages.map((item, j) => {
            if (j === i) {
              return {
                selectedLanguagesOption,
                selectedLevelOption:item.selectedLevelOption
              };
            } else {
              return item;
            }
          });
    
          return {
            languages,
          };
        });
    }

 
    handleLevelChange = (selectedLevelOption,i) => {

        this.setState(state => {
          const languages = state.languages.map((item, j) => {
            if (j === i) {
              return {
                
                selectedLevelOption,
                selectedLanguagesOption:item.selectedLanguagesOption
              };
            } else {
              return item;
            }
          });
    
          return {
            languages,
          };
        });
    }

  

  
    handleStartPressed(){ 
      // dont validate existance since is not mandetory
      let age = this.state.selectedOption   
      if(!age){
        age = {
          value:-1
        }
      }
      let gender = this.state.selectedGenderOption

      if(!gender){
        gender = {
          value:undefined
        }
      }

      let languages = undefined
      if(this.state.languages && (this.state.languages.length > 0) && 
          (this.state.languages[0].selectedLanguagesOption && this.state.languages[0].selectedLevelOption)
       ){
        languages = []
        this.state.languages.forEach(item => {
            let langItem = {
              language:item.selectedLanguagesOption.value,
              level:item.selectedLevelOption.value
            }
            languages.push(langItem)
        });
      }

      this.props.onStartPressed(age,JSON.stringify(languages),gender)

    }


    addLanguage() {
      console.log('laguages:',this.state.languages)
      // State change will cause component re-render
      this.setState({
        languages:this.state.languages.concat([
        {
          selectedLanguagesOption:null,
          selectedLevelOption:null
        }
        ])
      })
    }

    onRemoveLanguage = i => {
      this.setState(state => {
        const languages = state.languages.filter((item, j) => i !== j);
  
        return {
          languages,
        };
      });
    };

    showLanguageSection(languages){

      if(this.props.showLanguage){
        let addButtonClass = this.props.rtl  ?  'add-language-button-RTL' :  'add-language-button' 

        return (
          <div style={{
            margin: '10px'
          }}>
              {this.props.texts.languageTitle}
              {
                  this.getLanguages(languages)
              }
              
              <button  style={{
            marginTop: '10px',
            marginRight:'220px'
          }} onClick={() => {this.addLanguage()} } type="button" className="btn btn-secondary btn-sm">{this.props.texts.languageAddButton}</button>

          </div>
        )
      }
      else{
        return (<div></div>)
      } 
    }

    getLanguages(languages){
      if(this.props.rtl){
        return (  
          languages.map((item,index) => (
            <div key={index}
              style={{
                marginLeft:'auto',
                paddingTop: '10px',
       
                width: '100%',
                paddingRight:'0px'
              }}>

                
                <Select className =  "language-select-RTL"
                  placeholder = {this.props.texts.languagePlaceHolder}
                  value={item.selectedLanguagesOption}
                  onChange={(selectedOption) => this.handleLanguageChange(selectedOption,index)}
                  options={this.languagesOptions}
                  isSearchable = {false}

                />
                <Select className =  "language-select-RTL"
                  placeholder = {this.props.texts.languageLevelPlaceHolder}
                  value={item.selectedLevelOption}
                  onChange={(selectedOption) => this.handleLevelChange(selectedOption,index)}
                  options={this.levelsOptions}
                  isSearchable = {false}

                />

               
                <button style={{
                      marginLeft:'10px',
           
                      height: '35px'
                    }}
                    type="button"
                    onClick={() => this.onRemoveLanguage(index)}
                  >
                    {this.props.texts.languageRemoveButton}
                </button>
              
            </div>
          ))
        )
      }
      else{ 
        return (

          
          languages.map((item,index) => (
            <div key={index}
              style={{
                marginRight:'auto',
                paddingTop: '10px',

                width: '100%',
                paddingRight:'0px'
              }}>
                <Select className =  "language-select"
                  placeholder = {this.props.texts.languagePlaceHolder}
                  value={item.selectedLanguagesOption}
                  onChange={(selectedOption) => this.handleLanguageChange(selectedOption,index)}
                  options={this.languagesOptions}
                  isSearchable = {false}

                />
                <Select className =  "language-select"
                  placeholder = {this.props.texts.languageLevelPlaceHolder}
                  value={item.selectedLevelOption}
                  onChange={(selectedOption) => this.handleLevelChange(selectedOption,index)}
                  options={this.levelsOptions}
                  isSearchable = {false}

                />
                <button style={{
                      marginRight:'10px',
                      height: '35px'
                    }}
                    type="button"
                    onClick={() => this.onRemoveLanguage(index)}
                  >
                    {this.props.texts.languageRemoveButton}
                </button>
            </div>
          ))
        )
      }
    }
  
    render() {
      const { 
        gaveConsent,
        selectedOption,
        selectedGenderOption,
        languages
      } = this.state;


      let ageSelectClass = this.props.rtl  ?  'age-select-RTL' :  'age-select-LTR' 


      return (
        <div>
            {
            
              <div>

                <h1 className="title" >{this.props.texts.title}</h1>
                  <p> {this.props.texts.instruction}</p>
                  <div >
                    {this.props.texts.genderTitle}
                    <Select className =  {ageSelectClass}
                      placeholder = {this.props.texts.genderPlaceHolder}
                      value={selectedGenderOption}
                      onChange={this.handleGenderChange}
                      options={this.genderOptions}
                      isSearchable = {false}
                    />
                  </div>
                  <div >
                    {this.props.texts.ageTitle}
                    <Select className =  {ageSelectClass}
                      placeholder = {this.props.texts.agePlaceHolder}
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={this.options}
                      isSearchable = {false}
                    />
                  </div>

                  {this.showLanguageSection(languages)}
                  <button onClick={() => {this.handleStartPressed()} } type="button" className="btn btn-outline-dark btn-lg">{this.props.texts.startButton}</button>

              </div>
            }
     
        </div>
      )
    }
}

export default UserDetails;