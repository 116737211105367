import React, { Component } from 'react';
import { SimpleException,ExceptionTypes } from '../exceptions'
import Preview from './Preview'
import LeaveBrowserMessage from './LeaveBrowserMessage'
import errorReporter from '../errorReporter';
import platform from '../platform';

class ErrorBoundary extends Component {

    constructor(props) {
      super(props);
      this.state = { error: null };
    }
  
    componentDidCatch(error, info) {

      // if we arrive here we assume that we cant continue:
      // Display fallback UI
      this.setState({ 
        error,
      });

      // is preview ? (do nothing)
      if(error.type != ExceptionTypes.IsPreviewException){
          
          // Handle error:
          platform.onError(error)
      } 
      
    }


    render() {
      let { error } = this.state;

      if (error) {
        // is preview ?
        if(error.type == ExceptionTypes.IsPreviewException){
          return (
            <Preview isMobile={ error.isMobile}
                    mobileIsRequired={ error.mobileIsRequired}
                    texts={error.texts}
             />
          )
        }

        // is XIAOMI Browser? (no stream)
        if(error.type == ExceptionTypes.StreamDidntArriveException){
          return (
            <div className="AppCenter">
              <LeaveBrowserMessage 
                    
                    texts={error.message}
              />
            </div>
            
          )
        }

        // You can render any custom fallback UI
        return (
            <h2 className = "error" >{error.message}</h2>
        )

      }
      return this.props.children;
    }
  }

export default ErrorBoundary;