import React, { Component } from 'react';
import playground from '../playgroundv2'

class BallsRecord extends Component {
    
  constructor(){
    super()
    this.state = {
        startedRun:true,
        ballsStoped:false,
        showInstruction:true,
    }
} 

handleBallClicked(jackpot){
  //  not finished ? - do nothing
  if(this.state.startedRun && !this.state.ballsStoped){
      console.log('time hasnbt elapsed doing nothing')
      return 
  }
  if(jackpot){
      alert(this.props.texts.ballSelectedSuccess)

  }
  else{
      alert(this.props.texts.ballSelectedFail)
  }
  this.props.onDone(jackpot)
}

componentWillUnmount(){
  //this.pg.stop()
  clearTimeout(this.resetColor)
  clearTimeout(this.stop)
  clearTimeout(this.startMoving)
}
  
    componentDidMount(){
        console.log('rendering : BallsRecord')
        this.loadPlayground()
        this.setState({
          counter:this.props.ballsRecordingDelay
      })
    }

    loadPlayground(){
      const startMovingIn = this.props.ballsRecordingDelay*1000;

      var element = document.getElementById("playgroundId")
      let pg = playground.rerender(this.props.engine,element,
                                      (jackpot)=>{this.handleBallClicked(jackpot)},
                                   
                                      this.props.ballsCount,
                                      this.props.ballSelectedColor,
                                      this.props.ballsSpeed,
                                      this.props.ballsCollisionEnabled,
                                      this.props.ballsSpeedIsConstant,
                                      )

      this.pg = pg

      this.timerInterval = setInterval(() => {
        if(this.state.counter > 0 ){
            this.setState({counter:this.state.counter-1})
        }
       console.log('counter:',this.state.counter)
        

    }, (1000) );                                


    if(this.props.ballsSpeedIsConstant){
      this.interval = setTimeout(() => {

            pg.changeDirection()
      }, (startMovingIn) );
    }
    else{
      this.interval = setInterval(() => {

        pg.changeDirection()
      }, (startMovingIn) );
    }



    this.startMoving = setTimeout(() => {
        clearInterval(this.timerInterval)
        this.setState({ showInstruction:false})
        //this.props.onBallsStart()
    }, startMovingIn );

    this.resetColor = setTimeout(() => {
        pg.changeColor()
        
    }, this.props.ballRevealDuration *1000);

    this.stop = setTimeout(() => {
        clearInterval(this.interval)

        pg.stopBodies()
        this.setState({ ballsStoped:true})
        //this.props.onBallsStop()

    }, ( (this.props.movementDuration * 1000) + startMovingIn) );

    }
    
    render() {
      return (

        <div >
            <h1 style= {{fontSize: "60px", color: "red"}}  className="title" >{this.props.texts.title}</h1>
            { this.state.ballsStoped && 
             <p style={{fontSize: "25px", position:"absolute",top:'50%',right:'20%'}}  >{this.props.texts.instruction}</p>
            }
            <div id='playgroundId' >
    
            </div>
        </div>
      )
    }
}

export default BallsRecord;