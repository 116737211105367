import React, { Component } from 'react';
import {  osVersion,osName,fullBrowserVersion,browserVersion,browserName,mobileVendor,mobileModel,engineName,engineVersion,getUA,deviceType ,isMobile} from 'react-device-detect';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {openInBrowser,removeURLParameter} from '../utils'
import platform  from '../platform'
import {Bool} from '../const'

const INAPP_REDIRECT_BASE_DOMAIN = 'hebrew-uni-maril-lab.com'
const INAPP_FTP_BRIDAGE_PAGE = 'ftp://ftp.drivehq.com/shaharhalutz/publish/bridge2.html'

class InAppBrowser extends Component {

    constructor(props){
        super(props)
        this.state = {
            location:window.location,
            copied:false
        }
    }

    generateQueryParams(){
        var oldUrl = window.location.href
        // remove fbclid param (makes issues opening with ftp):
        oldUrl=removeURLParameter(oldUrl, "fbclid")

        var queryString = oldUrl ? oldUrl.split('?').pop() : window.location.search.slice(1);
        
        // add workerId (to prevent several Entry registation)
        queryString = queryString + '&workerId=' + platform.urlParams.workerId

        // replace fbclid: 
        queryString = queryString + '&fbclid=' + Bool.yes

        return queryString
    }

    handleIOSRedirect(){
        let queryString = this.generateQueryParams()
        let redirectURL = INAPP_FTP_BRIDAGE_PAGE + "?" + queryString
        openInBrowser('',redirectURL);
    }

    handleAndroidRedirect(){
        let queryString = this.generateQueryParams()
        var queryString_uri_enc = encodeURIComponent(queryString);            
        let redirectURL = "intent:https://amaril.page.link/?link=https://" + 
                            INAPP_REDIRECT_BASE_DOMAIN + "?" + 
                            queryString_uri_enc + "#Intent;end";

        window.location.href = redirectURL
    }

    componentDidMount(){
        switch (osName) {

            case 'iOS':
                this.handleIOSRedirect()
                break;

            case 'Android':
                this.handleAndroidRedirect()
                break;

            default:
                break;
        }    
    }

    render() {
      switch (osName) {
            case 'iOS':
                return (
                    <div>
                            <p className="title" > {this.props.texts.iosOpenInExternalBrowser}</p>

                            <button type="button" 
                                    className="btn btn-outline-dark btn-lg"
                                    onClick={ () => { this.handleIOSRedirect() } } >

                                {this.props.texts.iosOpenInExternalBrowserButton}

                            </button>
                    </div>
                )

            default:
                return (
                    <div>
                        <p className="title" >{this.props.texts.copyPasteInstruction}</p>
            
                        <CopyToClipboard text={this.state.location}
                                         onCopy={() => {this.setState({copied: true})}} >
                        
                            <button type="button" 
                                    className="btn btn-outline-dark btn-lg">

                                {this.props.texts.copyButton}

                            </button>
            
                        </CopyToClipboard>
                    </div>
                )       
      }
    }
}

export default InAppBrowser;