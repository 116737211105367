import React, { Component } from 'react';
import playground from '../playgroundv2'

class BallsInstruction extends Component {
    componentDidMount(){
        console.log('rendering : BallsInstruction')
        this.loadPlayground()
    }

    componentWillUnmount(){
      this.pg.stop()
    }



    loadPlayground(){

      var element = document.getElementById("playgroundId")
      this.pg = playground.rerender(this.props.engine,element,
                                      (jackpot)=>{this.handleBallClicked(jackpot)},
                                      
                                      this.props.ballsCount,
                                      this.props.ballSelectedColor,
                                      this.props.ballsSpeed,
                                      this.props.ballsCollisionEnabled,
                                      this.props.ballsSpeedIsConstant,

                                      )

  }




    render() {
      return (
        <div>
            <h1 className="title" >{this.props.texts.title}</h1>
            <p className = "instruction" > {this.props.texts.category}</p>
            <div id='playgroundId' >
    
            </div>
        </div>
      )
    }
}

export default BallsInstruction;