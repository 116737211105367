import React, { Component } from 'react';

class BallsRecordStop extends Component {


    componentDidMount(){
      this.props.onStopPressed()

    }

    render() {

      return (
        <div>
   
        </div>
      )
    }
}

export default BallsRecordStop;