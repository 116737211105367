import React, { Component } from 'react';

class PreInstruction2 extends Component {



    getInstruction(){
      let instructions = []
      for (let index = 0; index < this.props.texts.instruction.length; index++) {
        const instructionStr = this.props.texts.instruction[index];
        instructions.push( <p key = {index} >{instructionStr}</p>)
      }

     return instructions
    }

    handleOnClick(){
      if(!this.clicked){
        this.clicked = true
        this.props.onStartPressed()
      }
      else{
        console.log('already clcikde')
      }
 

    }

    render() {

      return (
        <div>
            <h1 className="title" >{this.props.texts.title}</h1>
            {this.getInstruction()}
            <button onClick={()=>{this.handleOnClick()} } type="button" className="btn btn-outline-dark btn-lg">{this.props.texts.startButton}</button>

        </div>
      )
    }
}

export default PreInstruction2;