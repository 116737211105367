import React, { Component } from 'react';

class Done extends Component {


    
    render() {
      return (
        <div>
           { this.props.showAssignmentId &&
             <h1 className="title" >  {this.props.texts.aiNumber} :  {this.props.assignmentId}</h1>
            }
            <h1 className="title" >{this.props.texts.title}</h1>
        </div>
      )
    }
}

export default Done;