import React, { Component } from 'react';

class RecordStop extends Component {

    componentDidMount(){
        
        //console.log('submitDelay:',this.props.submitDelay)
        this.submitDelayTimout = setTimeout( () => {
          
          this.props.onStopPressed()
        }, 7 * 1000); 

        setTimeout( () => {
          let beepElement = document.getElementById("beepElement");
          beepElement.crossOrigin = 'anonymous';
          console.log(beepElement)
          try {
            //
            //beepElement.play()
    
          } catch (error) {
            
          }
        }, 1 * 1000);
    }

    render() {

      return (
        <div>
            <audio id="beepElement" src="https://mturk-manage-2018-04-16.s3-eu-west-1.amazonaws.com/EChime.mp3" preload = "true" autoPlay = {false}></audio>

            <h1 className="title" >{this.props.texts.title}</h1>
            <p >{this.props.texts.instruction}</p>
            
              <button  onClick={() => {
                if(this.clicked){
                  console.log('already clicked')
                  return 
                }
                this.clicked = true
                clearTimeout( this.submitDelayTimout);
                this.props.onStopPressed()
             
              } 
              } type="button" className="btn btn-outline-dark btn-lg">{this.props.texts.stopButton}</button>

        </div>
      )
    }
}

export default RecordStop;