
import React from 'react';
import { setPlatformName,PlatformEndpoints,PlatformTypes,Default, Bool, FlowStates } from "./const";
import { getUrlParam ,createRandomFilename,updateAssignmentItem} from "./utils";
import errorReporter from './errorReporter';
import { SimpleException } from './exceptions'

class Platform {
 
    constructor () {
      this.listenToNavigateAway()

    }

    navigateAwayMessage(e){
      var msg = 'Are you sure you want to leave the page?';
      (e || window.event).returnValue = msg;
      return msg;
    }

    listenToNavigateAway(){
      var evt = window.attachEvent || window.addEventListener;

      var checkEvt = window.attachEvent ? 'onbeforeunload' : 'beforeunload';

      evt(checkEvt, this.navigateAwayMessage);
    }

    removeListenToNavigateAway(){
      console.log('removing listen')

      var evt = window.detachEvent || window.removeEventListener;

      var checkEvt = window.detachEvent ? 'onbeforeunload' : 'beforeunload';

      evt(checkEvt, this.navigateAwayMessage);
    }

    renderSubmitForm(assignmentId){
        return (<div/>)
    }

    setURLParams(){
      return
    }

    
    setAssignmentItem(assignmentItem){
      console.log('setting assignmentItem:',assignmentItem)
      this.assignmentItem = assignmentItem
    }

    setAppState(state){
      this.appState = state
    }

    onError(error){
      // notnhing
    }
   
}

class IpanelPlatform extends Platform{
 
  constructor () {
    super();
    this.type = PlatformTypes.ipanel

    // const:
    this.paramTypes = {
      approved:'completed',
      screened  :'screened'
    }

    this.setURLParams()

  }

  setURLParams(){
    var url = window.location.href;

    // set App URL params:
    this.urlParams = {
      configFilename:getUrlParam(url,'ConfigFilename',Default.configFilename),
      devEnv:getUrlParam(url,'DevEnv',Default.devEnv),
      isSandbox:getUrlParam(url,'IsSandbox',Default.isSandbox),
      assignmentId:getUrlParam(url,'assignmentId',createRandomFilename()),
      fbclid:getUrlParam(url,'fbclid',null),

      // ipanel codes:
      workerId:getUrlParam(url,'ipanelid',createRandomFilename()),
      hitId:getUrlParam(url,'i.project',Default.hitId),
      accessCode:getUrlParam(url,'i.user1',null),
    }
  }


  onError(error){

    // sanity:
    let type = error.type
    if(!type){
      type = 'UnexpectedError'
    }
    let message = error.message
    if(!message){
      message = JSON.stringify(error)
    }

    // report :
    errorReporter.report(message,type ,this.urlParams.workerId, () =>{
        // after report is complete we can redirect
        let approved = false; 

        // make sure we save assignment Item before returning:
        // (we want to make sure we save an assignment once a person has done recording)
        if(this.assignmentItem){
          updateAssignmentItem(this.urlParams.assignmentId,this.assignmentItem,(error,res) => {
            approved = true
            this.returnToPlatform(approved);    
          })
        }
        else{
          // for Ipanel : if no assignmentItem we still return to platform 
          approved = false
          this.returnToPlatform(approved);    
        }  
    })
  }

  returnToPlatform(approved){


    this.removeListenToNavigateAway()      

    let returnURL = PlatformEndpoints.ipanel.redirect 

    returnURL += '?i.project=' + this.urlParams.hitId
    returnURL += '&i.user1=' + this.urlParams.accessCode 
    returnURL += '&id=' + this.urlParams.workerId 

    // add ipanel codes:
    returnURL += '&i.user9='; 

    if(approved){
      returnURL +=  this.paramTypes.approved
    }
    else{
      returnURL +=  this.paramTypes.screened
    }
    window.location.replace(returnURL);
  }

  submitAssignment(assignmentId,cb){
    console.log('submitAssignment '+this.type+' assignmentId:',assignmentId)

    //document.getElementById("endForm").submit();// Form submission
    
    // 1. Create a new XMLHttpRequest object
    let xhr = new XMLHttpRequest();

    // 2. Configure it: GET-request for the URL /article/.../load
    xhr.open('GET', PlatformEndpoints.ipanel.submitAssignment+'?assignmentId='+assignmentId);

    // 3. Send the request over the network
    xhr.send();

    // 4. This will be called after the response is received
    xhr.onload = () => { 
      if (xhr.status != 200) { 
        console.log(`Error ${xhr.status}: ${xhr.statusText}`);
        console.log('Error submit assignment - response: ',xhr.response)
        // TBD: get error message:
        if(cb){
          cb('error submitting assignment: '+assignmentId)
        }

      } else { // show the result
        console.log('assignment submitted, reposnse:',xhr.response); // responseText is the server
        //alert('the assignment was submitted succesfuly, your assignment Id is: '+assignmentId)
        if(cb){
          cb(null,xhr.response);
        }
      }
    };

    //xhr.onprogress = function(event) {
      //if (event.lengthComputable) {
      //  console.log(`Received ${event.loaded} of ${event.total} bytes`);
      //} else {
      //  console.log(`Received ${event.loaded} bytes`); // no Content-Length
      //}
    //};

    xhr.onerror = () => { 
      console.log("Request failed");
      if(cb){
        cb('error submitting assignment'+assignmentId)
      }
    };
    
    return;
  }
 
}   

class PanelForAllPlatform extends IpanelPlatform {
 
  constructor () {
    super();
    this.type = PlatformTypes.panelForAll

    // const:
    this.paramTypes = {
      finish:'finish',
      filterout  :'filterout'
    }

    this.setURLParams()


  }

  setURLParams(){
    var url = window.location.href;

    // set App URL params:
    this.urlParams = {
      configFilename:getUrlParam(url,'ConfigFilename',Default.configFilename),
      devEnv:getUrlParam(url,'DevEnv',Default.devEnv),
      workerId:getUrlParam(url,'userID',createRandomFilename()),
      isSandbox:getUrlParam(url,'IsSandbox',Default.isSandbox),
      assignmentId:getUrlParam(url,'assignmentId',createRandomFilename()),
      // hitId
      hitId:getUrlParam(url,'surveyID',Default.hitId),
      fbclid:getUrlParam(url,'fbclid',null),

    }
  }

  returnToPlatform(approved){


    this.removeListenToNavigateAway()  
    
    //http://www.panel4all.co.il/survey_runtime/external_survey_status.php?surveyID=XXXX&userID=YYYYYYYYYYYYY&status=finish

    let param = '&status='; 

    if(approved){

      param += this.paramTypes.finish
    }
    else{
      param += this.paramTypes.filterout
    }

    window.location.replace(
      PlatformEndpoints.panelForAll.redirect + '?' + 'surveyID=' + this.urlParams.hitId + '&userID=' + this.urlParams.workerId + param
    );
  }

}   

class MturkPlatform extends Platform {
   
    constructor () {
      super();
      this.type = PlatformTypes.mturk

      this.setURLParams()

      if( this.urlParams.isSandbox == Bool.yes){
        this.mturkSubmitURL = PlatformEndpoints.mturk.submitAssignment.sandbox
      }
      else{
        this.mturkSubmitURL = PlatformEndpoints.mturk.submitAssignment.production
      }
    }
  
    setURLParams(){
      var url = window.location.href;

      // set App URL params:
      this.urlParams = {
        configFilename:getUrlParam(url,'ConfigFilename',Default.configFilename),
        devEnv:getUrlParam(url,'DevEnv',Default.devEnv),
        workerId:getUrlParam(url,'workerId',Default.workerId),
        hitId:getUrlParam(url,'hitId',Default.hitId),
        assignmentId:getUrlParam(url,'assignmentId',createRandomFilename()),
        isSandbox:getUrlParam(url,'IsSandbox',Default.isSandbox),
        fbclid:getUrlParam(url,'fbclid',null),

      }
    }

    submitAssignment(assignmentId,cb){
      console.log(this.type+' submitAssignment doing nothing ... ')
      if(cb){
        cb(null,"true")
      }
    }


    onError(error){

      // sanity:
      let type = error.type
      if(!type){
        type = 'UnexpectedError'
      }
      let message = error.message
      if(!message){
        message = JSON.stringify(error)
      }

      // report :
      errorReporter.report(message,type ,this.urlParams.workerId, () =>{
          // after report is complete we redirect only if work was complete:
       
          // make sure we save assignment Item before returning:
          // (we want to make sure we save an assignment once a person has done recording)
          if(this.assignmentItem){
            updateAssignmentItem(this.urlParams.assignmentId,this.assignmentItem,(error,res) => {
              this.returnToPlatform()    
            })
          }     
        })
    }

    returnToPlatform(){
      this.removeListenToNavigateAway()      


      console.log(this.type+' returnToPlatform: ')
  
      // submit:
      if(this.urlParams.devEnv == Bool.no){
        document.getElementById("endForm").submit(); 
      }
  
      return;
    }

    renderSubmitForm(assignmentId){
        return (
            <form id = "endForm" action={this.mturkSubmitURL} method="POST">
                <input type="hidden" id="assignmentId" value={assignmentId} name="assignmentId"/>
                <input type="hidden" id="user-input" value="" name="user-input"/>   
            </form>
        )
    } 
   
  }

class ProlificPlatform extends IpanelPlatform {
  constructor () {
    super();
    this.type = PlatformTypes.prolific
    this.setURLParams()
  }

  setURLParams(){
    var url = window.location.href;

    // set App URL params:
    this.urlParams = {
      configFilename:getUrlParam(url,'ConfigFilename',Default.configFilename),
      completionCode:getUrlParam(url,'CompletionCode',''),
      devEnv:getUrlParam(url,'DevEnv',Default.devEnv),
      workerId:getUrlParam(url,'PROLIFIC_PID',createRandomFilename()),
      isSandbox:getUrlParam(url,'IsSandbox',Default.isSandbox),
      assignmentId:getUrlParam(url,'SESSION_ID',createRandomFilename()),
      // hitId
      hitId:getUrlParam(url,'STUDY_ID',Default.hitId),
      fbclid:getUrlParam(url,'fbclid',null),


    }
  }

  submitAssignment(assignmentId,cb){
    console.log(this.type+' submitAssignment doing nothing ... ')
    if(cb){
      cb(null,"true")
    }
  }


  returnToPlatform(approved){
 
    this.removeListenToNavigateAway()  
    window.location.replace(
      PlatformEndpoints.prolific.redirect + '?' + 'cc=' + this.urlParams.completionCode
    );
    
  }


  onError(error){

    // sanity:
    let type = error.type
    if(!type){
      type = 'UnexpectedError'
    }
    let message = error.message
    if(!message){
      message = JSON.stringify(error)
    }

    // report :
    errorReporter.report(message,type ,this.urlParams.workerId, () =>{
        // after report is complete we can redirect
        let approved = false; 

        // make sure we save assignment Item before returning:
        // (we want to make sure we save an assignment once a person has done recording)
        if(this.assignmentItem){
          updateAssignmentItem(this.urlParams.assignmentId,this.assignmentItem,(error,res) => {
            approved = true
            this.returnToPlatform(approved);    
          })
        }
        else{
          // for Ipanel : if no assignmentItem we still return to platform 
          approved = false
          // BUGFIX: if on prolific dont return on already exists. (we dont want to pay for nothing)
          if(error.type != 'WorkerAlreadyParticipated'){
            this.returnToPlatform(approved);
          }
          
        }  
    })
  }

}   


  class FreePlatform extends Platform{
 
    constructor () {
      super();
      this.type = PlatformTypes.free
      this.setURLParams()
    }
  
    setURLParams(){
      var url = window.location.href;
  
      // set App URL params:
      this.urlParams = {
        configFilename:getUrlParam(url,'ConfigFilename',Default.configFilename),
        devEnv:getUrlParam(url,'DevEnv',Default.devEnv),
        workerId:getUrlParam(url,'workerId',createRandomFilename()),
        isSandbox:getUrlParam(url,'IsSandbox',Default.isSandbox),
        assignmentId:getUrlParam(url,'assignmentId',createRandomFilename()),
        // hitId
        hitId:getUrlParam(url,'hitId',Default.hitId),
        fbclid:getUrlParam(url,'fbclid',null),

  
      }
    }
  
  
    onError(error){
  
      // sanity:
      let type = error.type
      if(!type){
        type = 'UnexpectedError'
      }
      let message = error.message
      if(!message){
        message = JSON.stringify(error)
      }
  
      // report :
      errorReporter.report(message,type ,this.urlParams.workerId, () =>{
          // after report is complete we can redirect
          let approved = false; 
  
          // make sure we save assignment Item before returning:
          // (we want to make sure we save an assignment once a person has done recording)
          if(this.assignmentItem){
            updateAssignmentItem(this.urlParams.assignmentId,this.assignmentItem,(error,res) => {
              approved = true
              this.returnToPlatform(approved);    
            })
          }
          else{
            // for Ipanel : if no assignmentItem we still return to platform 
            approved = false
            this.returnToPlatform(approved);    
          }  
      })
    }
  
  
    returnToPlatform(approved){
  
  
      this.removeListenToNavigateAway()      
  
      console.log('approved:',approved)
     
    }
  
    submitAssignment(assignmentId,cb){
      console.log('submitAssignment '+this.type+' assignmentId:',assignmentId)
  
      //document.getElementById("endForm").submit();// Form submission
      
      // 1. Create a new XMLHttpRequest object
      let xhr = new XMLHttpRequest();
  
      // 2. Configure it: GET-request for the URL /article/.../load
      xhr.open('GET', PlatformEndpoints.ipanel.submitAssignment+'?assignmentId='+assignmentId);
  
      // 3. Send the request over the network
      xhr.send();
  
      // 4. This will be called after the response is received
      xhr.onload = () => { 
        if (xhr.status != 200) { 
          console.log(`Error ${xhr.status}: ${xhr.statusText}`);
          console.log('Error submit assignment - response: ',xhr.response)
          // TBD: get error message:
          if(cb){
            cb('error submitting assignment: '+assignmentId)
          }  
        } else { // show the result
          console.log('assignment submitted, reposnse:',xhr.response); // responseText is the server
          //alert('the assignment was submitted succesfuly, your assignment Id is: '+assignmentId)
          if(cb){
            cb(null,xhr.response)
          }
        }
      };
  
      //xhr.onprogress = function(event) {
        //if (event.lengthComputable) {
        //  console.log(`Received ${event.loaded} of ${event.total} bytes`);
        //} else {
        //  console.log(`Received ${event.loaded} bytes`); // no Content-Length
        //}
      //};
  
      xhr.onerror = () => { 
        console.log("Request failed");
        if(cb){
          cb('error submitting assignment: '+assignmentId)
        }    
      };
      
      return;
    }
   
  }   



  function identifyPlatform (url){

    let platformTypeParam = getUrlParam(url,'PlatformType',null)
    
    if(platformTypeParam && Object.values(PlatformTypes).includes(platformTypeParam)){
      return platformTypeParam
    }

    let surveyIDParam = getUrlParam(url,'surveyID',null)
    let userIDParam = getUrlParam(url,'userID',null)

    if(surveyIDParam &&  userIDParam){
      return PlatformTypes.panelForAll
    }

    let prolificIDParam = getUrlParam(url,'PROLIFIC_PID',null)

    if(prolificIDParam ){
      return PlatformTypes.prolific
    }
    
    let ipanelParam = getUrlParam(url,'ipanelid',null)

    if(ipanelParam ){
      return PlatformTypes.ipanel
    }
 
    return PlatformTypes.free
  }

  function createPlatform() {
    // identify platform:
    var url = window.location.href;
    let platformType = identifyPlatform(url)
    // TBD: change PlatformName to platform.type
    setPlatformName(platformType)

    switch (platformType) {
      case PlatformTypes.mturk:
        return new MturkPlatform()
      
      case PlatformTypes.ipanel:
        return new IpanelPlatform()
      
      case PlatformTypes.free:
        return new FreePlatform()
         
      case PlatformTypes.panelForAll:
        return new PanelForAllPlatform()  

      case PlatformTypes.prolific:
        return new ProlificPlatform()  
  

      default:
          break;
    } 
    
    return null;
  }
  let platfrom = createPlatform()
  console.log('createPlatform purlarams',platfrom.urlParams)

  export default platfrom
