// TBD: BUG : hasParticipated not checked in backend
// TBD: BUG ; when clicking many times on start it pushes forward state ...





// TBD:
// add to Questionaire 2 more dynamoc forms if a gignoses was selected in Q1:
// 1. yes/no
// 2. american 

// add gender consideration in UserDetail screen (then add 2 config options for screens onwards 'Done_female')

// HERE : 
// -fix visuals by using schema package
// test end to end
// TBD: later :  save true / false not yes no ... (save values not labels )

// - add questionair to the dynamic form,
// - test if assignmentItem is saved correctly


// TBD: User Details:
// - add male/female

// TBD: find better solution for having defulat values when screens are skkiped 
// since to continue to nextState we demand be diffrerent than initial values (!= '--') 

// TBD: add isSandbox to entries
// TBD: check if both stop button and timeout are called to make a redudunt nextstate call



// TBD: first put assiignmentItem then upload (since errors will be due to upload time we need the assignmentItem saved)
// TBD: test end to end when encoding = flac

// TBD: make sure backend recieves correct lamnguage that was identified in client
// TBD: make sure correct language is enforced

// TBD: go over ipanel submit back end - reunite Back ends ? and Tables?
// TBD: deal with return value of submition ipanel
// TBD: ipanel platform diffs: 
//      V - Props not in URL: load only 3 parameters from url (sandbox,configFIle) rest from json
//      V - Language: add RTL classes in quiz and consent
//      V - assigment/Error submition
// TBD: texts-heb : translate ExtraDataQuestion ,Quiz,RecordingTimeQuestion
// TBD: parasms: add all of them in the constructor (and later overwtite with data load?)
        // allow overriting ? (after load...)


// TBD: change all xhr.open to use axios 
import React, { Component } from 'react';
import './App.css';
import myWorker from './test.worker';

// components:
import Welcome from './components/Welcome';
import Instruction from './components/Instruction';
import Record from './components/Record';
import TouchTask from './components/TouchTask';
import RecordingTimeQuestion from './components/RecordingTimeQuestion'
import UserEmailQuestion from './components/UserEmailQuestion'
import WorkerIdQuestion from './components/WorkerIdQuestion'
import ExtraDataQuestion from './components/ExtraDataQuestion'
import Done from './components/Done';
import NoiseDetector from './components/NoiseDetector';
import PreInstruction from './components/PreInstruction';
import PreInstruction2 from './components/PreInstruction2';
import PreInstruction0 from './components/PreInstruction0';

import RecordStop from './components/RecordStop';
import Questionnaire from './components/Questionnaire';
import UserDetails from './components/UserDetails'
import InAppBrowser from './components/InAppBrowser'
import BallsPreInstruction from './components/BallsPreInstruction';
import BallsInstruction from './components/BallsInstruction';
import BallsRecordStop from './components/BallsRecordStop';
import BallsRecord from './components/BallsRecord';
import pgv2 from './playgroundv2'



//import DynamicForm from  './components/DynamicForm'
// app:
import { isInApp, createRandomFilename, getParam,getDeviceInfo, updateEntryItem, updateAssignmentItem , createPlatform, identifyPlatform} from './utils'
import { FlowStates, setMessages ,setPlatformName ,Messages ,Endpoints,Default,Bool,AMARIL_SANDBOX_DIRECTORY_PREFIX,ASSIGNMENT_ID_NOT_AVAILABLE ,PlatformName, ObjectTypes, PlatformTables, PlatformTypes} from './const'
import { SimpleException,IsPreviewException,ExceptionTypes } from './exceptions'

// libs:
import NoSleep from 'nosleep.js';
import MobileDetect from 'mobile-detect'
import errorReporter from './errorReporter';
import axios  from 'axios';
import Quiz from './components/Quiz';
import platform  from './platform'

import { mobileVendor,browserName,isMobile,} from 'react-device-detect';

const SECOND = 1000

class App extends Component {
  constructor() {
    super();

    // set App URL params:
    if(platform){
      this.params = platform.urlParams
      console.log('app constructor',platform)
    }
    else{
      throw new Error('platform was not identified.')
    } 

    this.state = {
      flowState: FlowStates.initial,
      stream : null,
      counter:1000000,
      percent: 0,

      //counter: this.params.recordingDuration + this.params.delayDuration,
    };
    this.percievedRecordingTime = '--';
    this.extraData = '--';
    this.multiAnswer = '--';
    this.userEmail = '--';
    //this.assignmentItemExtras = {}

    this.md = new MobileDetect(window.navigator.userAgent);
    this.workerAge = 0;
    this.languagesOption = []
    this.noiseDetectionFails = 1
    this.uploadComplete = false

  }

  // progress bar in touch screen
  increase() {
    const { percent } = this.state;
    const newPercent = percent + 1;
    if (newPercent >= 100) {
      clearTimeout(this.tm);
      return;
    }
    this.setState({ percent: newPercent });
    this.tm = setTimeout(()=>{this.increase()}, 10);
  }

  componentWillUpdate(nextProps, nextState) {
    console.log('componentDidUpdate: nextState:',nextState.flowState)

    // flow state has changed ?
    if (nextState.flowState !== this.state.flowState) {
      console.log('flow state will change to: ',nextState.flowState)


      // check already participated (local storage): 
      let entryId = this.params.workerId + '_' + this.params.hitId

      if(this.params.isSandbox != Bool.yes){
          
        if( nextState.flowState == FlowStates.done ){
          console.log('Local storage: setting category,:',this.params.category)
          //localStorage.setItem("assignmentId", this.params.assignmentId);
          localStorage.setItem("category", this.params.category);

          // remove navigate away message:
          if(platform.type == PlatformTypes.free){
            platform.removeListenToNavigateAway()
          }
        }
      }

      platform.setAppState(nextState.flowState)

      // document flow state on Entry :
      var createdAt = JSON.stringify(new Date()) 
      createdAt = createdAt.slice(1, -1);
      var platformName = PlatformName;
      var experimentName = this.params.experimentName;

      // send data:
      var item =     {
        assignmentId:this.params.assignmentId, 
        workerId:this.params.workerId,
        createdAt,
        platform:platformName,
        experimentName,
        hitId:this.params.hitId,
        //errorMessage: '--', 
        //subMultiAnswererrorType: '--',
        lastAppState: nextState.flowState    
      }

      // add fbRedirect column: 
      if(platform && platform.urlParams && platform.urlParams.fbclid){
        item.fbclid = platform.urlParams.fbclid
      }
         
      updateEntryItem(entryId,item, (error,res) => {
        console.log('updateEntryItem result:',res)
        if(error){
          errorReporter.report('Error, onFlowStateChange:updateEntryItem failed.',JSON.stringify(error),error.workerId);
        }
        // dont leave before updating entry
        if( nextState.flowState == FlowStates.done ){
          this.onDone() 
        }
      })
    }
  }

  isPreview(){
    return (this.params.assignmentId == ASSIGNMENT_ID_NOT_AVAILABLE)
  }

  onStreamActive(stream){
    // got Stream:
    console.log('Media stream succesfully created');
    this.init(stream)
    this.startRecording()
    //this.checkAverageNoise()
  }

  getMicStream(cb){
    console.log('getMicStream')

    // get Stream:
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log('getUserMedia supported.');

      // BUGFIX : XAIOMI MI browser promise does not resolve:
      // before getting Stream -> set timeout to check we have resolved the promise:
      console.log('getUserMedia noiseSamplingDuration.', this.params.noiseSamplingDuration)
      setTimeout(() => {

        // check if stream had arrived ?
        if(this.state.stream){
          console.log('resolved userMedia')
        }
        else{

          if(browserName == "MIUI Browser"){
            console.log('Error - could not resolved getUserMedia',this.state)

            // Error :
            let type = ExceptionTypes.StreamDidntArriveException
            this.setState({
              error:new  SimpleException(this.text.LeaveBrowserMessage,type,this.params.workerId)
            });
            return ; 
          }

        }
        
      },1000 * (10));

      navigator.mediaDevices.getUserMedia ({audio: true})
         // Success callback
         .then((stream) => {
            cb(stream) 
         })
         // Error callback
         .catch((err) =>{
            console.log(err)
            
            let errorMessage = Messages.BrowserNotCompatible;
            if((''+err).includes("NotAllowedError") ){ 
              errorMessage = Messages.RequireMicEnable;
            }
            else if( (''+err).includes("NotFoundError")){
              errorMessage = Messages.MicNotFound;
            }

            // Error :
            const type = 'GetUserMediaException:'+err
            this.setState({
              error:new SimpleException(errorMessage,type,this.params.workerId)
            })
            return;
          });
   } 
   else{
      // trying alternative: // TBD: remove promises
      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
      if(navigator.getUserMedia){
        console.log('navigator.getUserMedia supported.');

        navigator.getUserMedia ({audio: true})
         // Success callback
         .then((stream) => {
           cb(stream)
         })
         // Error callback
         .catch((err) =>{
            console.log(err)
              
            let errorMessage = Messages.BrowserNotCompatible;
            if((''+err).includes("NotAllowedError") ){ 
              errorMessage = Messages.RequireMicEnable;
            }
            else if( (''+err).includes("NotFoundError")){
              errorMessage = Messages.MicNotFound;
            }

            // Error :
            const type = 'GetUserMediaException:'+err
            this.setState({
              error:new SimpleException(errorMessage,type,this.params.workerId)
            })
            return;
         }
        );
      }
      else{
        const type = 'GetUserMediaException: not supported'
        this.setState({
          error:new SimpleException(Messages.BrowserNotCompatible,type,this.params.workerId)
        })
        return; 
      }
   }
}


  componentDidMount() {

    //alert(window.location.href)

    // progress bar :
    this.increase()

    // init error reporter:
    let serviceName =  PlatformName + (this.isSandbox() ? '_sandbox' : '')
    console.log('service_nane:',serviceName)
    let reporterEnabled = (this.params.devEnv == Bool.no) ? true : false
    errorReporter.init(reporterEnabled,serviceName,this.params)
            
    const isMobile = this.isMobile()
    const mobileIsRequired = this.params.enforceOnMobile == Bool.yes

    // user has accepted -  has already done a survey ?
    this.getServerData( (errorMessage,data) => {
      // note: Error Boundary not called in Async,so set error on state and throw in componentDidUpdate.
      let dataObj

      if(!data && !errorMessage){
        // report server data Issue:
        errorMessage = 'Error, server data is null.'
      }
      
      // parse data:
      try{
        dataObj = JSON.parse(data)
      }
      catch(error){
        errorMessage = 'Error parsing server data.'
      }
      
      if(errorMessage){
        this.setState({
          error:new SimpleException(Messages.LoadingError,errorMessage,this.params.workerId)
        })
        return;
      }
      console.log('getServerData: success, data:', dataObj)
      
      // load params:
      
      if(dataObj && dataObj.ux){
        this.setParams(dataObj.ux)
      }
      else{
        this.setState({
          error:new SimpleException(Messages.LoadingError,'ErrorNoParams',this.params.workerId)
        })
        return; 
      }

      // load texts:
      if(dataObj && dataObj.text){
        this.text = dataObj.text
        setMessages(dataObj.text.Messages)
      }
      else{
        this.text = {}
        errorReporter.report('Warning, server data is  missing text, falling back to defualts. configFilename:'+this.params.configFilename,'serverDataMissingText',this.params.workerId);
      }

      // is Preview ?
      // preview ? (cut short here since we dont have the workerId in preview) 
      if( this.isPreview() ){

        this.setState({
          error:new  IsPreviewException(isMobile,mobileIsRequired,this.text.Preview)
        });
        return ; 
      }
      
      
      // Worker Already Participated ?
      console.log('enforceDeviceBlock:',this.params.enforceDeviceBlock )

      if( (this.params.enforceDeviceBlock == Bool.yes) && (this.params.isSandbox != Bool.yes)){
        
        // TBD :get new param : category
        
        console.log('getting storage: category',this.params.category)

        let ai = localStorage.getItem("assignmentId");
        let categoryThatExists = localStorage.getItem("category");


        console.log('Local storage: got assignmentId,categoryThatExists:',ai,categoryThatExists)
        // Worker Already Participated ?
        if((categoryThatExists == this.params.category) ||
          //(backwards compatibility)
          (ai && this.params.category == 'animals')){

          let assignmentItem = {lastEntryAttempt:JSON.stringify(new Date()) }
          updateAssignmentItem(ai,assignmentItem,(error,res) => {
            alert(Messages.WorkerAlreadyParticipated)
            if(platform.type != PlatformTypes.prolific){
              platform.returnToPlatform(false)
            }
          })

          const type = 'WorkerAlreadyParticipated'
          this.setState({
            error:new SimpleException(Messages.WorkerAlreadyParticipated,type,this.params.workerId)
          })
          return;     
        }
        
      }

      // meets language requirements ? 
      if(dataObj.isBlocked){
        const type = Messages.WorkerLanguageRequirement
        this.setState({
          error:new SimpleException(Messages.WorkerLanguageRequirement,type,this.params.workerId)
        })
        return;       
      }

      // on mobile?:
      if(!isMobile && mobileIsRequired){
        const type = 'NotMobileException'
        this.setState({
          error:new SimpleException(Messages.notOnMobile,type,this.params.workerId)
        })
        return; 
      }

      // on desktop?:
      if(isMobile && (this.params.enforceOnDesktop == Bool.yes)){
        const type = 'NotDesktopException'
        this.setState({
          error:new SimpleException(Messages.notOnDesktop,type,this.params.workerId)
        })
        return; 
      }

      // got audio context?:
      this.audio_context =  this.getAudioContext()
      if(!this.audio_context){
        const type = 'GetAudioContextException'
        this.setState({
          error:new SimpleException(Messages.BrowserNotCompatible,type,this.params.workerId)
        })
        return;
      }

    // before proceeding to screen create balls engine:
    if(this.params.ballsEnabled == Bool.yes){
      this.engine =  pgv2.createEngine(
        this.params.ballsCount,
        this.params.ballSelectedColor,
        this.params.ballsSpeed,
        (this.params.ballsCollisionEnabled == Bool.yes)
      )
    }

     this.setState({      
        flowState: this.nextFlowState(),
      })
    }) 
  }

  init(stream){
    // audio context must be resumed  after a user gesture: 
    // (https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#webaudio)
    this.audio_context.resume()
    // init encoder:
    this.initEncoder(stream)
    
    // init NoSleep:
    this.initNoSleep()
    
    this.setState({
      flowState: this.nextFlowState(),
      stream,
      counter: this.params.recordingDuration + this.params.delayDuration,
    })
  }

  isMobile(){
    
    console.log('mobile:',this.md.mobile())
    return ( this.md.mobile() ? true : false)
  }

  initNoSleep(){

    this.noSleep = new NoSleep();

    // Enable wake lock.
    // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
    //console.log('noSleep : will be enabled on click')

    let enableNoSleep = () => {
      
      document.removeEventListener('click', enableNoSleep, false);
      this.noSleep.enable();
      console.log('noSleep.enable')
    }
    
    document.addEventListener('click', enableNoSleep , false);
  }

  initEncoder(stream){

    // get node and input:
    this.input = this.audio_context.createMediaStreamSource(stream);
    if(this.input.context.createJavaScriptNode){
      this.node = this.input.context.createJavaScriptNode(4096, 1, 1);
    }
    else if(this.input.context.createScriptProcessor){
      this.node = this.input.context.createScriptProcessor(4096, 1, 1);
    }
    else {
      const type = 'Could not create audio node for JavaScript based Audio Processing.'
      this.setState({
        error:new SimpleException(Messages.EncoderInitFailed,type,this.params.workerId)
      })
      return; 
    }  
    
    // for noise detection
    this.analyser = this.audio_context.createAnalyser();
    this.analyser.smoothingTimeConstant = 0.8;
    this.analyser.fftSize = 2048;

    this.input.connect(this.analyser);
    this.analyser.connect(this.node);
    //javascriptNode.connect(audioContext.destination);   

    this.flacdata = {};
    this.flacdata.bps = 16;
    this.flacdata.channels = 1;
    this.flacdata.compression = 5;
    // this.samplerate = 44100
    this.samplerate = this.audio_context.sampleRate; 
    // init encoder:
    // DEBUG:
    console.log('initializing encoder :');
    //console.log(' bits-per-sample = ' + this.flacdata.bps);
    //console.log(' channels        = ' + this.flacdata.channels);
    console.log(' sample rate     = ' + this.samplerate);
    //console.log(' compression     = ' + this.flacdata.compression);

    this.encoder = new myWorker();
    //worker.postMessage(this.state.counter);
    //worker.addEventListener('message', event => this.setState({counter: event.data}));

   // BUG iphone 6 (& 6s) ios 11 : encoder is null and still being called 
   this.encoder.onmessage =  e => {
    //this.encoder.addEventListener('message', e => {

      console.log('this.encoder.onmessage ',e)

      if(e.data.cmd == 'initError'){
    
        const type = 'EncoderInitException:'
        this.setState({
          error:new SimpleException(Messages.EncoderInitFailed,type,this.params.workerId)
        })
        return;
      }

      if (e.data.cmd == 'end') {
        // upload:        
        this.onEncoderDone(e.data.buf)

        // terminate encoder:
        this.encoder.terminate();
        this.encoder = null;

        // report Error:
        if(e.data.encodingError){
          errorReporter.report( Messages.EncodingFailed,
                                'EncodingException:'+e.data.encodingError,
                                this.params.workerId);
        }
      }

      if (e.data.cmd == 'error') {
        // HERE:  TEST this
        console.log('encoder : error event has been fired, stopping ...  , error:',e.data.error)

        this.onRecordingTimeElapsed()
      }
    }

    this.encoder.postMessage({ 
      cmd: 'init', 
      config: {
        samplerate: this.samplerate, 
        bps: this.flacdata.bps, 
        channels: this.flacdata.channels, 
        compression:this.flacdata.compression  
      },
      encoding: this.params.encoding 
    });

    this.node.onaudioprocess = (e)=>{

      try {
        
        console.log('onaudioprocess ..')

        // recording:
        if(this.state.flowState == FlowStates.record){
            // see also: http://typedarray.org/from-microphone-to-wav-with-getusermedia-and-web-audio/
            var channelLeft  = e.inputBuffer.getChannelData(0);
            // var channelRight = e.inputBuffer.getChannelData(1);
            //this.encoder.onmessage({ cmd: 'encode', buf: channelLeft});
            this.encoder.postMessage({ cmd: 'encode', buf: channelLeft });

            console.log('encoding Data ..')
        }

        // detecting noise:
        if(this.state.flowState == FlowStates.detectNoise){
          var array = new Uint8Array(this.analyser.frequencyBinCount);
          this.analyser.getByteFrequencyData(array);
          var values = 0;
    
          var length = array.length;
          for (var i = 0; i < length; i++) {
            values += (array[i]);
          }
    
          var average = values / length;
          console.log('DB:',average)
          this.samples.push(average)

        }
      } catch (error) {
        // Error while recording - stop recording:
        this.onErrorWhileRecording(error)
        return;
          
      }
    };
  }

  onErrorWhileRecording(error){
    // stop clock:
    clearInterval(this.clock);

    // stop NoSleep:
    console.log('noSleep.disable')
    this.noSleep.disable();

    // stop recording:
    this.stopRecording()

    const type = 'ErrorWhileRecording'
    this.setState({
      error:new SimpleException('Error While Recording:'+JSON.stringify(error),type,this.params.workerId)
    })
  }

  checkAverageNoise(){

    let getAverage = (samples) => {

        if(!samples || samples.length == 0){
            return null;
        }
        // calc average:
        let total = 0;
        for (let index = 0; index < samples.length; index++) {
            const sample = samples[index];
            total += sample
        }
        let average = total / samples.length
        return average
    
    }

    this.samples=[]
    this.checkAverageNoiseTimeout = setTimeout(() => {
        // clear current timeout:
        clearTimeout(this.checkAverageNoiseTimeout)
        
        let averageNoise = getAverage(this.samples);
        
        let hasPassed = (averageNoise == null) || 
                        (averageNoise < this.params.noiseDetectionDbThreshold);
        console.log('hasPassed:' +hasPassed + ' ,noise detected: '+ averageNoise +', threshold: ' +this.params.noiseDetectionDbThreshold)


        // has passed noise detection?
        if( hasPassed ){
            //alert('has Passed noise detection' + ' ,noise detected: '+ averageNoise +', threshold: ' +this.props.noiseDetectionDbThreshold)
              this.averageNoise = averageNoise
              this.setState({
                flowState: this.nextFlowState(),
              })
             
        }
        else{
            console.log(Messages.DecreaseBackgroundNoise+' ,noise detected: '+ averageNoise +', threshold: ' +this.params.noiseDetectionDbThreshold)

            this.noiseDetectionFails = this.noiseDetectionFails + 1
            if(this.noiseDetectionFails == 3){
              alert(Messages.DecreaseBackgroundNoise+', this is your last attempt.')
            }
            else if(this.noiseDetectionFails < 3){
              alert(Messages.DecreaseBackgroundNoise)
            }


            if(this.noiseDetectionFails > 3){
            
              const type = 'NoiseDetectionFailed:'
              this.setState({
                error:new SimpleException(Messages.NoiseDetectionFailed,type,this.params.workerId)
              })
              return;
            }

     
            this.checkAverageNoise()  
        }

    }, (this.params.noiseSamplingDuration*SECOND) ); 
  }

  onEncoderDone(blob) {

    if(!blob){
      errorReporter.report( Messages.EncodingFailed,
        'onEncoderDoneErrorBlobNull:blob is null',
        this.params.workerId);
    }    

    // sanity:
    if(this.state.signedUrl){
      console.log('have signedUrl:',this.state.signedUrl);
      this.uploadAudio(this.state.signedUrl,blob)
    }
    else{
      errorReporter.report( 'signedURL is null',
                            'signedURLNull',
                            this.params.workerId);
    }
  
  }

  isRealAssignmentId(){
    if(this.params.assignmentId.length < 6 ){
      return false
    }
    return true
  }


  getAssignmentItem(cb){

    let data = {
      objectType: ObjectTypes.assignment,
      itemKey: this.params.assignmentId,
    }

    axios({
      method: 'post',
      url: Endpoints.getItem,
      data: data
    })
    .then( (response)=> {
      console.log('getAssignmentItem:',response);
      if(cb){
        cb(null,response)
      }
    })
    .catch( (error) => {
      
      if(cb){
        cb(error,null)
      }
    });
  }

  createAssignmentItem (){

    var isMobile = this.isMobile();
    var deviceInfo = getDeviceInfo(this.md);
    var percievedRecordingTime = this.percievedRecordingTime
    var extraData = this.extraData;
    var userEmail = this.userEmail;
    var sampleRate = this.audio_context.sampleRate;
    var filename = this.audioFilename;
    var isSandbox = this.isSandbox()
    var experimentName = this.params.experimentName;
    var workerAge = this.workerAge;
    var averageNoise = ''+Math.floor(this.averageNoise);
    var platform = PlatformName;
    var lang = this.params.language;
    var createdAt = JSON.stringify(new Date()) 
    createdAt = createdAt.slice(1, -1);
    var secondsRecorded = this.params.recordingDuration - this.state.counter 
    var secondsRequested = this.params.recordingDuration 
    var diagnoses = this.diagnoses
    var meds = this.meds
    var therapy = this.therapy
    var severity = this.severity
    var meditationYears = this.meditationYears
    var meditationMinutes = this.meditationMinutes
    var gender = this.gender
    var languages = this.languages
    var correctBallSelected = this.correctBallSelected
    var category = this.params.category
    var country = this.params.country

    // send data:
    var item =     {
      hitId:this.params.hitId,
      //id:this.params.assignmentId, 
      workerId:this.params.workerId, 
      isMobile,
      deviceInfo,
      percievedRecordingTime,
      extraData,
      userEmail,
      sampleRate,
      filename,
      isSandbox,
      experimentName,
      workerAge,
      averageNoise,
      platform,
      lang,
      createdAt,
      secondsRecorded,
      secondsRequested,
      diagnoses,
      meds,
      therapy,
      severity,
      meditationYears,
      meditationMinutes,
      gender,
      languages,
      correctBallSelected,
      category,
      country,

    }
    return item
  }

  handleAssignmentSubmitted(errorMessage,result){
    this.approved = false;
    if(errorMessage || !result){
      errorReporter.report('Error, submitAssignment failed.'+errorMessage,'submitAssignmentForValidation:ErrorSubmitAssignment',this.params.workerId,()=>{
      });
      this.approved = true
    }
    else{
      if((result == 'true')){
        this.approved = true
      }
    }
    
    console.log('submitAssignmentForValidation, returned from submit assignment , result:',result)
    
    if(this.state.flowState == FlowStates.done){
      console.log('submitAssignmentForValidation,state is done - returned from submit assignment - returning to Platform, result:',result)

      platform.returnToPlatform(this.approved)
    }

  }

  submitAssignmentForValidation(){
    // before we can submit we have to save the assignment:
    let assignmentItem = this.createAssignmentItem()
    updateAssignmentItem(this.params.assignmentId,assignmentItem,(error,res) => {
      if(error){
          errorReporter.report('Error, ErrorUpdateAssignmentItem failed.'+JSON.stringify(error),'submitAssignmentForValidation:ErrorUpdateAssignmentItem',this.params.workerId,()=>{
          });
      }
      platform.submitAssignment(this.params.assignmentId,(errorMessage,result)=>{
        this.handleAssignmentSubmitted(errorMessage,result)
      })
    })
  }

  onDone(){

    console.log('onDone: ,this.approved',this.approved)

    // TBD: show spinner if not in free platform
    // before we finish - save the assignment:
    let assignmentItem = this.createAssignmentItem()
    updateAssignmentItem(this.params.assignmentId,assignmentItem,(error,res) => {
      if(error){
          errorReporter.report('Error, ErrorUpdateAssignmentItem failed.'+JSON.stringify(error),'onDone:ErrorUpdateAssignmentItem',this.params.workerId,()=>{
            if(this.approved != undefined){
              
              platform.returnToPlatform(this.approved)
            }
          });
      }
      else{
        if(this.approved != undefined){
          console.log('onDone: returning to plat this.approved',this.approved)

          platform.returnToPlatform(this.approved)
        }

      }
 
    })
  }

  handleRecordingTimeDonePressed(seconds){
    console.log("handleRecordingTimeDonePressed, seconds:",seconds);
    if(!seconds || seconds == ''){
      seconds = '--'
    }
    // assign to user-input before submiting:
    this.percievedRecordingTime = seconds
 
    this.setState({
      flowState: this.nextFlowState(),
    })
  }
  handleWorkerIdDonePressed(workerId){
    console.log("handleWorkerIdDonePressed, workerid:",workerId);
    if(workerId && workerId != ''){
      this.params.workerId = workerId
    }
 
    this.setState({
      flowState: this.nextFlowState(),
    })
  }

  handleUserEmailDonePressed(email){
    console.log("handleUserEmailDonePressed, email:",email);
    if(!email || email == ''){
      email = '---'
    }
    // assign to user-input before submiting:
    this.userEmail = email
 
    this.setState({
      flowState: this.nextFlowState(),
    })
  }

  onUploadComplete(){
    // not to call twice
    if(this.uploadComplete){
      return;
    }

    console.log('onUploadComplete : ')
    this.uploadComplete = true;

    // submit assignment when upload is completed:
    this.submitAssignmentForValidation()

    // in touch screen ?
    if(this.state.flowState == FlowStates.touchTask){
      // finish
      this.setState({
        flowState: this.nextFlowState(),
      })
    }
  }

  uploadAudio(signedUrl,blob){
    console.log('uploadAudio:')
    axios({
      method: 'put',
      url: signedUrl,
      data: blob,
      headers: {
        'Content-Type': 'audio/flac'
      },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log('uploadAudio: '+percentCompleted+'% ')
        this.setState({percent:percentCompleted})
      }
    })
    .then( (response) => {
      this.onUploadComplete()
    })
    .catch( (error) => {
       
      errorReporter.report( 'uploadAudioFailed:'+JSON.stringify(error),
                            'uploadAudioFailed' ,
                            this.params.workerId )
      
      this.onUploadComplete()

    });

    // checkIfDone:
    this.checkIfDoneDelay = setTimeout(() => {this.checkIfDone()}, (this.params.checkIsUploadDoneDelay*SECOND) ); 

  }



  checkIfDone(){
    console.log('checkIfDone')
    if(this.state.flowState == FlowStates.done){
      console.log('checkIfDone: already in done, dont poll.')
      return
    }

    // The polling function
    function poll(fn, timeout, interval) {
      var endTime = Number(new Date()) + (timeout || 2000);
      interval = interval || 100;

      var checkCondition = function(resolve, reject) { 
          var ajax = fn();
          // dive into the ajax promise
          ajax.then( function(response){
              console.log('checkCondition : response:',response)

              // If the condition is met, we're done!
              // TBD: decople app from db app shouldnt know iners of db struct (response.data.Item.audioFileCreated
              if((response.data &&  response.data.Item &&  response.data.Item.audioFileCreated) &&
                  response.data.Item.audioFileCreated == 'true'){
                  resolve(true);
              }
              // If the condition isn't met but the timeout hasn't elapsed, go again
              else if (Number(new Date()) < endTime) {
                  setTimeout(checkCondition, interval, resolve, reject);
              }
              // Didn't match and too much time, reject!
              else {
                  reject(new Error('timed out for ' + fn + ': ' + arguments));
              }
          });
      };

      return new Promise(checkCondition);
    }

    // Usage: get something via ajax
    poll( () => {
      let data = {
        objectType: ObjectTypes.assignment,
        itemKey: this.params.assignmentId,
      }
  
      return axios({
        method: 'post',
        url: Endpoints.getItem,
        data: data
      })
      
    }, this.params.checkIsUploadDoneTimeout*SECOND, this.params.checkIsUploadDoneInterval*SECOND).then(()=> {
        // Polling done, now do something else!
        console.log('pollin condition met')
        this.onUploadComplete()
    }).catch(() => {
        console.log('pollin timed out')
        this.onUploadComplete()
    });

  }

  getNextQuestionState(){
    if(this.workerAge == 0){
      return FlowStates.userDetails;
    }
    if(this.params.askExtraData == Bool.yes &&
      this.extraData == '--'){
      return FlowStates.extraDataQuestion;
    }
    if(this.params.askRecordingDuration == Bool.yes &&
      this.percievedRecordingTime == '--'){
      return FlowStates.timeQuestion
    }
    if(this.params.askMultiAnswerQuestion == Bool.yes &&
      this.multiAnswer == '--'){
      return FlowStates.multiAnswerQuestion;
    }
    if(this.params.askUserEmailQuestion == Bool.yes &&
      this.userEmail == '--'){
      return FlowStates.userEmailQuestion;
    }
    return null
  }

nextFlowState(){
    let nextFlowState = FlowStates.initial;

    switch (this.state.flowState) {
      case FlowStates.initial:
        // in FB App Browser ?
        if (isInApp(["FBAN", "FBAV"]) && isMobile) {
        //if(true){
          nextFlowState = FlowStates.handleInAppBrowser
        }
        else{
          // enforce welcome (language) ? 
          nextFlowState = (this.params.enforceLanguage == Bool.yes) ? 
            FlowStates.welcome : 
              // enforce quize?
              (this.params.quizEnabled == Bool.yes) ? 
                FlowStates.quiz : 
                FlowStates.preWelcome;
        }
        break;

      // we dont break free from handleInAppBrowser state
      case FlowStates.handleInAppBrowser: 
        nextFlowState = FlowStates.handleInAppBrowser
        break;

      case FlowStates.welcome:
        nextFlowState = (this.params.quizEnabled == Bool.yes) ? FlowStates.quiz : FlowStates.welcomeInstruction;
        break;

      case FlowStates.quiz:
        nextFlowState = FlowStates.preWelcome;
        break;

      case FlowStates.preWelcome:
        nextFlowState = FlowStates.welcomeInstruction;
        break;
  
      case FlowStates.welcomeInstruction:
          nextFlowState = FlowStates.preInstruction;
          break;
      case FlowStates.preInstruction:
        nextFlowState = FlowStates.instruction;
        break;

      case FlowStates.detectNoise:
        nextFlowState = FlowStates.instruction;
        break;

      case FlowStates.instruction:
        nextFlowState = FlowStates.record;
        break;

      case FlowStates.record:
        nextFlowState = FlowStates.recordStop;
        break;

      case FlowStates.workerIdQuestion: 
          nextFlowState = FlowStates.userDetails;
          break;
      
      case FlowStates.recordStop:
        if( this.params.askWorkerIdQuestion == Bool.yes ){
          return FlowStates.workerIdQuestion;
        }
        else{
            let nextQuestionState = this.getNextQuestionState()
            if(nextQuestionState){
              nextFlowState = nextQuestionState
            }
            else{
                // no questions are needed :
                if(this.uploadComplete){
                  nextFlowState = FlowStates.done;
                }
                else{
                  nextFlowState = FlowStates.touchTask;
                }
            }
                break;
        }


      case FlowStates.userDetails:
      case FlowStates.timeQuestion:
      case FlowStates.extraDataQuestion:
      case FlowStates.multiAnswerQuestion:
      case FlowStates.userEmailQuestion:
        let nextQuestionState = this.getNextQuestionState()
        if(nextQuestionState){
          nextFlowState = nextQuestionState
        }
        else{
            // no questions are needed :
            if(this.uploadComplete){
              nextFlowState = FlowStates.done;
            }
            else{
              nextFlowState = FlowStates.touchTask;
            }
        }
            break;

      case FlowStates.touchTask:
        nextFlowState = FlowStates.done;
        break;


      default:
        nextFlowState = FlowStates.initial;
        break;

    }
    
    return nextFlowState;
  }

  handlePreInstructionDonePressed(result){
    console.log('balls test run result:',result)

    this.getMicStream((stream) => {
      this.startClock();
      this.onStreamActive(stream)
    }) 
    

    
    /*
    this.setState({
      flowState: this.nextFlowState(),
    }, ()=>{
      this.startClock()
    })
    */
  }

  handleStopPressed(){
    // BUGFix stop only once :
    if(this.state.flowState == FlowStates.recordStop){
      this.setState({
        flowState: this.nextFlowState(),
      })
    }
  
  }
  
  start(averageNoise){

    this.averageNoise = -1//averageNoise;
    
    this.getSignedURL( (signedUrl) => {
        
      this.setState({
        flowState: this.nextFlowState(),
        signedUrl
      })
    })
  }

  // TBD: move methjod to Welcome compoeoent and send as param?
  hasLanguage(languages,languageToEnforce,levelToEnforce){
    let foundEnglish = false
    for (let index = 0; index < languages.length; index++) {

      if(languages[index].selectedLanguagesOption && 
         languages[index].selectedLevelOption){
        const language = languages[index].selectedLanguagesOption.value;
        const level = languages[index].selectedLevelOption.value;

        if(language.toLowerCase() == languageToEnforce.toLowerCase() && 
            level.toLowerCase() == levelToEnforce.toLowerCase()  ){
          foundEnglish = true
          break;
        }  
      }

    }
    return foundEnglish
  }

  handleUserDetailsDonePressed(ageOption,languages,genderOptions){
      this.workerAge = ageOption.value
      this.gender = genderOptions.value
      this.languages = languages

      this.setState({
        flowState: this.nextFlowState(),
      })
      return 
  }

  handleWelcomeDonePressed(ageOption,languages){


    // ENFORCE LANGUAGE:
    let languageToRequire = this.params.language == 'heb' ? "עברית" : 'English'
    let levelToRequire = this.params.language == 'heb' ? "שפת אם" : 'Fluent'

    let foundEnglish = this.hasLanguage(languages,languageToRequire,levelToRequire)
    if(!foundEnglish && this.params.enforceLanguage == Bool.yes){
      console.log('didnt find language ! blocking user') 
      const type = 'didnt find language ! blocking user'
      this.setState({
        error:new SimpleException(Messages.WorkerLanguageRequirement,type,this.params.workerId)
      })
      // block Worker
      this.blockWorker( (error,res) => {
            
        if(error || !res){
          errorReporter.report('Error, blockWorker failed error:'+JSON.stringify(error),'blockWorkerError:' ,this.params.workerId )
        }
        else{
          console.log('blockWorker succesfult result:',res)
        }
      })
      return;
    }
    
    this.setState({
      flowState: this.nextFlowState(),
    })
    return 
  }

  startClock() {

    // clock:
    this.clock = setInterval(()=> {
      this.onTick()

    }, SECOND);
  }

  stopRecording(){
    console.log('stopRecording')
    
    // stop tracks:
		var tracks = this.state.stream.getAudioTracks()
		for(var i = tracks.length - 1; i >= 0; --i){
			tracks[i].stop();
    }
    
    // tell encoder to finish:
		this.encoder.postMessage({ cmd: 'finish' });
        
    // disconnect from mic input:
    this.input.disconnect();
    this.node.disconnect();
    
    this.input = null
    this.node = null;
  }

  startRecording(){
    console.log('startRecording')
    this.input.connect(this.node);
    this.node.connect(this.audio_context.destination);
  }

  onTick(){
    console.log('counter:',this.state.counter)
    
    if(this.state.counter == this.params.recordingDuration+2){
      // sound BIP:
      var audio = new Audio("https://mturk-manage-2018-04-16.s3-eu-west-1.amazonaws.com/EChime.mp3");
      try {
        //
        //audio.play();

      } catch (error) {
        
      }
    }
    // instruction delay ended ?
    if(this.state.counter == this.params.recordingDuration+1){
      
      // start recording:
      //this.startRecording()
      
      // flowState -> recording
      this.setState({
        flowState: this.nextFlowState(),
        counter:this.params.recordingDuration
      });
    }

    // time up ?
    else if(this.state.counter === 0){      
      this.onRecordingTimeElapsed()
    }
    else{
      this.updateAISecondsRecorded()

      // progress counter:
      this.setState({
        counter: this.state.counter - 1
      })
    }
  }

  updateAISecondsRecorded(){
      // update assignmentItem.secondsRecorded:
      let ai = platform.assignmentItem
      if(!ai){
        ai = this.createAssignmentItem()
      }
      ai.secondsRecorded = this.params.recordingDuration - this.state.counter 
      platform.setAssignmentItem(ai)
  }

  onRecordingTimeElapsed(){
    console.log('onRecordingTimeElapsed')
    // stop clock:
    clearInterval(this.clock);

    // stop NoSleep:
    console.log('noSleep.disable')
    this.noSleep.disable();

    // stop recording:
    this.stopRecording()

    // in balls we stay in record state.
    if(this.params.ballsEnabled != Bool.yes){
      this.setState({
        flowState: this.nextFlowState()
      })
    }
  }

  setParams(obj){
    let language = getParam(obj,'Language',Default.language)
    let countryDefault = (language == 'eng') ?  'US' : ((language == 'heb') ?  'IL' :  null )

    let loadedParams = {
      // Mturk:
      //assignmentId:getParam(obj,'assignmentId',createRandomFilename()),
      //hitId:getParam(obj,'hitId',Default.hitId),
      //workerId:getParam(obj,'workerId',Default.workerId),
      
      // ux:
      //devEnv: getParam(obj,'DevEnv',Default.devEnv),
      recordingDuration: parseInt(getParam(obj,'RecordingTime',Default.recordingDuration)),
      delayDuration: parseInt(getParam(obj,'DelayDuration',Default.delayDuration)),
      experimentName: getParam(obj,'ExperimentName',Default.experimentName),
      askRecordingDuration: getParam(obj,'AskRecordingDuration',Default.askRecordingDuration),
      askExtraData: getParam(obj,'AskExtraData',Default.askExtraData),
      enforceOnMobile: getParam(obj,'EnforceOnMobile',Default.enforceOnMobile),
      enforceOnDesktop: getParam(obj,'EnforceOnDesktop',Default.enforceOnDesktop),
      ageCutoff: parseInt(getParam(obj,'AgeCutoff',Default.ageCutoff)),
      noiseDetectionDbThreshold: parseInt(getParam(obj,'NoiseDetectionDbThreshold',Default.noiseDetectionDbThreshold)),
      noiseSamplingDuration: parseInt(getParam(obj,'NoiseSamplingDuration',Default.noiseSamplingDuration)),
      askExtraData:getParam(obj,'AskExtraData',Default.askExtraData),
      askMultiAnswerQuestion:getParam(obj,'AskMultiAnswerQuestion',Default.askMultiAnswerQuestion),
      multiAnswerTimeToAnswer : parseInt(getParam(obj,'MultiAnswerTimeToAnswer',Default.multiAnswerTimeToAnswer)),
      askUserEmailQuestion:getParam(obj,'AskUserEmailQuestion',Default.askUserEmailQuestion),
      askWorkerIdQuestion:getParam(obj,'AskWorkerIdQuestion',Default.askWorkerIdQuestion),

      //isSandbox:getParam(obj,'IsSandbox',Default.isSandbox),
      //configFilename:getParam(obj,'ConfigFilename',Default.configFilename),
      extraDataSubmitDelay: parseInt(getParam(obj,'ExtraDataSubmitDelay',Default.extraDataSubmitDelay)),
      userDetailsSubmitDelay: parseInt(getParam(obj,'UserDetailsSubmitDelay',Default.userDetailsSubmitDelay)),

      timeQuestionSubmitDelay: parseInt(getParam(obj,'TimeQuestionSubmitDelay',Default.timeQuestionSubmitDelay)),
      userEmailQuestionSubmitDelay: parseInt(getParam(obj,'UserEmailQuestionSubmitDelay',Default.userEmailQuestionSubmitDelay)),
      checkIsUploadDoneDelay: parseInt(getParam(obj,'CheckIsUploadDoneDelay',Default.checkIsUploadDoneDelay)),
      checkIsUploadDoneInterval: parseInt(getParam(obj,'CheckIsUploadDoneInterval',Default.checkIsUploadDoneInterval)),
      checkIsUploadDoneTimeout: parseInt(getParam(obj,'CheckIsUploadDoneTimeout',Default.checkIsUploadDoneTimeout)),
      quizTimeToAnswer : parseInt(getParam(obj,'QuizTimeToAnswer',Default.quizTimeToAnswer)),
      quizPassThreshold : parseInt(getParam(obj,'QuizPassThreshold',Default.quizPassThreshold)),
      language,
      enforceLanguage:getParam(obj,'EnforceLanguage',Default.enforceLanguage),
      encoding:getParam(obj,'Encoding',Default.encoding),
      quizEnabled:getParam(obj,'QuizEnabled',Default.quizEnabled),
      // balls:
      ballsEnabled:getParam(obj,'BallsEnabled',Default.ballsEnabled),
      ballsCount: parseInt(getParam(obj,'BallsCount',Default.ballsCount)),
      ballsPracticeDuration: parseInt(getParam(obj,'BallsPracticeDuration',Default.ballsPracticeDuration)),
      ballsSpeed: parseInt(getParam(obj,'BallsSpeed',Default.ballsSpeed)),
      ballRevealDuration: parseInt(getParam(obj,'BallRevealDuration',Default.ballRevealDuration)),
      ballSelectedColor: getParam(obj,'BallSelectedColor',Default.ballSelectedColor),
      ballsCollisionEnabled : getParam(obj,'BallsCollisionEnabled',Default.ballsCollisionEnabled),
      ballsRecordingDelay: parseInt(getParam(obj,'BallsRecordingDelay',Default.ballsRecordingDelay)),
      ballsSpeedIsConstant: getParam(obj,'BallsSpeedIsConstant',Default.ballsSpeedIsConstant),

      country: getParam(obj,'Country',countryDefault),
      category: getParam(obj,'Category',Default.category),
      askMeditationQuestion:getParam(obj,'AskMeditationQuestion',Default.askMultiAnswerQuestion),
      enforceDeviceBlock:getParam(obj,'EnforceDeviceBlock',Default.enforceDeviceBlock),

    }
    this.params = Object.assign({},this.params ,loadedParams)

    console.log('this.params:',this.params)
  }

  isSandbox(){
    return (this.params.isSandbox == Bool.yes)
  }

  getSubmitURL(){
    var url = Endpoints.submitAssignment.production
    if( this.isSandbox() ){
      url = Endpoints.submitAssignment.sandbox
    }
    return url
  }

  getAudioContext(){
    let audio_context = null
    if(typeof window.webkitAudioContext !== 'undefined'){
      audio_context = new window.webkitAudioContext();
    }
    else if(typeof window.AudioContext !== 'undefined'){
      audio_context = new window.AudioContext();
    }
    return audio_context;
  }

  // getBucket dev / prod (according to DEV_ENV)
  getBucket(){
    //if(this.params.devEnv == Bool.yes){
    //  return 'recording_test_dev/'
    //} 
    return 'recordings_test/'
  }

  getFilename(){
    return this.params.assignmentId + '.' + this.params.encoding;
  }

  blockWorker(cb){
    console.log('blockWorker function')
    const xhr = new XMLHttpRequest();
    xhr.open("POST", Endpoints.blockWorker, true);
    
    // on load:
    xhr.onload = () => {
      const status = xhr.status;
      if (status === 200) {
        var data = xhr.response;
        cb(null ,data )
      } 
      else {
        var error = 'Error: status is not 200'
        cb(error ,null )
      }

    };
    
    xhr.onerror = (data) => {
        var error = 'Error: error occured.'
        cb(error ,null )  
    };

    xhr.setRequestHeader('Content-Type', 'application/json');

    // send data:
    var item =     {
      id:this.params.workerId,
      isBlocked:true 
    }

    const tableName = 'MturkWorkers';//( this.isSandbox() ? 'MturkAssignmentsSandbox' : 'MturkAssignments')

    const data = {
      item,
      tableName
    }

    xhr.send( JSON.stringify(data) );
  }

  putEntry(item,cb){

    let data = {
      experimentName:this.params.experimentName,
      objectType:ObjectTypes.entry,
      itemKey:this.params.workerId,
      item:item,
    }

    axios({
      method: 'post',
      url: Endpoints.updateItem,
      data: data,
    })
    .then( (response) => {
      if(cb){
        cb(null,response)
      }
      else{
        console.log('putEntry cb is null')
      }
    })
    .catch( (error) => {
      if(cb){
        cb(error,null)
      }
      else{
        console.log('putEntry:Error, cb is null')
      }
    });
  }

  getServerData(cb){

    const xhr = new XMLHttpRequest();
    xhr.open("POST", Endpoints.workerExistsInProd, true);
    
    // on load:
    xhr.onload = () => {
      const status = xhr.status;
      if (status === 200) {
        var data = xhr.response;
        cb(null ,data )
      } 
      else {
        var error = 'Error: getServerData - status is not 200'
        cb(error ,null )  
      }
    };
    
    xhr.onerror = (data) => {
        var error = 'Error: getServerData - error occured.'
        cb(error ,null )  
    };

    xhr.setRequestHeader('Content-Type', 'application/json');

    // send data:
    const data = {
      workerId:this.params.workerId,
      configFilename: this.params.configFilename,
      hitId: this.params.hitId,
    }
    console.log('getServerData params:',data)
    xhr.send( JSON.stringify(data) );
  }

  getSignedURL(cbSuccess){

    const bucketDir = this.getBucket()
    const fileName = this.getFilename();
    const folder = PlatformName + '/' +
                   this.params.experimentName + '/' +
                   this.params.hitId + '___' +this.params.language + 
                   ((this.workerAge > this.params.ageCutoff) ? '/over_'+this.params.ageCutoff : '')
    const bucketName = bucketDir + folder ;
    console.log('this.workerAge:',this.workerAge)
    console.log('this.params.ageCutoff:',this.params.ageCutoff)


    console.log('getSignedURL for filename:',fileName)
    var apiEndpoint = 'https://gz9mat81pd.execute-api.eu-west-1.amazonaws.com/dev/getSignedURL';   
    const xhr = new XMLHttpRequest();
    xhr.open("POST", apiEndpoint, true);
    
    // on load:
    xhr.onload = () => {
      const status = xhr.status;
      if (status === 200) {
        // Got signedURL:
        var responseObj = JSON.parse(xhr.response);
        console.log('success, got SignedUrl:', responseObj.url)
        cbSuccess ( responseObj.url )
      } 
      else {
        errorReporter.report( 'GetSignedUrlException:',
                              'GetSignedUrlException' ,
                              this.params.workerId )
        
      }
    };
    
    xhr.onerror = (data) => {
      errorReporter.report( 'GetSignedUrlException:'+JSON.stringify(data),
                            'GetSignedUrlException' ,
                            this.params.workerId )
    };

    xhr.setRequestHeader('Content-Type', 'application/json');

    // send data:
    const data = {
      fileName, 
      bucketName
    }
    xhr.send( JSON.stringify(data) );
    this.audioFilename = 'gs://' +data.bucketName+'/'+data.fileName
  }

  handleExtraDataDonePressed(data){
    console.log("handleExtraDataDonePressed, extra data:",data);
    this.extraData = data
 
    this.setState({
      flowState: this.nextFlowState(),
    })
  }

  handleQuestionnaireDonePressed(data){
    if(data){
      this.multiAnswer = data
      const YES_heb = "כן"
      const YES_en = "yes"

      if(data.meds && data.meds!="--"){
        this.meds = ( (data.meds == YES_heb) ||  (data.meds.toLowerCase() == YES_en) ) 
      }
      let diagnosesStr = "" 
      if(data.diagnoses){
        data.diagnoses.forEach(item => {
          diagnosesStr += (',' +item) 
        });
      }
      
      this.diagnoses = (diagnosesStr == "") ? undefined : diagnosesStr.substr(1)
      if(data.treatment && data.treatment!="--"){
        this.therapy = ( (data.treatment == YES_heb) ||  (data.treatment.toLowerCase() == YES_en) )
      }
      let severity = data.severity && data.severity.length ? parseInt(data.severity[0]) : undefined
      if(!severity){
          severity = undefined
      } 
      this.severity = severity

      let meditationYears = data.meditationYears && data.meditationYears.length ? (data.meditationYears) : undefined
      if(!meditationYears){
        meditationYears = undefined
      } 
      this.meditationYears = meditationYears

      let meditationMinutes = data.meditationMinutes ? (data.meditationMinutes) : undefined
      this.meditationMinutes = meditationMinutes

    }
    else{
      console.log("Questionaire was not filled" );
      this.multiAnswer = '---'
    }

    this.setState({
      flowState: this.nextFlowState(),
    })
  }

  handleQuizDone(passed){
    console.log('handleQuizDone: did pass:',passed)

    if(passed){
      // CONTINUEate To Welcome:
      this.setState({      
        flowState: this.nextFlowState(),
      })
    }
    else{
      const type = 'ErrorQuizFailed:'
        this.setState({
          error:new SimpleException(Messages.QuizFailed,type,this.params.workerId)
        })
        return;
    }
  }

  getQuizParams(){
    let passThreshold = parseInt(Default.quizPassThreshold)
    let timeToAnswer = parseInt(Default.quizTimeToAnswer)
    let instructions = this.text.Quiz.Instructions

    try {
      passThreshold = parseInt(this.text.Quiz.PassThreshold)
    } 
    catch (error) {
      console.log('FlowStates.quiz: ',error)
    }

    try {
      timeToAnswer = parseInt(this.text.Quiz.TimeToAnswer)
    } 
    catch (error) {
      console.log('FlowStates.quiz: ',error)

    }

    let questions = this.text.Quiz.Questions
    let title = this.text.Quiz.Title

    return {
      instructions,
      questions,
      timeToAnswer,
      passThreshold,
      title,
    }

  }

  renderAppState(){
    let rtl = this.params.language == 'heb' 


    switch (this.state.flowState) {

      case FlowStates.initial:
        return <h1 className="title" >Loading...</h1>

      case FlowStates.handleInAppBrowser:              
        return (
          <div>
            <InAppBrowser  
              texts = {this.text.InAppBrowser}              
            />                               
          </div> 
        )

      case FlowStates.welcome:

        return ( 
          <div >
            <Welcome  rtl = {rtl}
                      texts = {this.text.Consent}
                      onStartPressed = {(ageOption,languagesOption) => this.handleWelcomeDonePressed(ageOption,languagesOption)}
            />     
          </div> 
        )

             
      case FlowStates.quiz:

        let {questions,timeToAnswer,title,passThreshold,instructions} = this.getQuizParams()
        return (
          <Quiz rtl = {rtl}
                texts={this.text.Quiz} 
                timeToAnswer = {this.params.quizTimeToAnswer}
                passThreshold = {this.params.quizPassThreshold}

                onDone = {(passed)=>{this.handleQuizDone(passed)}}
                >
          </Quiz>
        )
      // HERE:  
      // http://localhost:3000/?ConfigFilename=shahar_test_101021.json&IsSandbox=yes 
      case FlowStates.preWelcome:
        return (
          <div>
            <PreInstruction0 
              onStartPressed  = {() => {
                this.setState({flowState: this.nextFlowState()})
              }} 
              texts = {this.text.PreWelcome}              
            />                               
          </div> 
        )

      case FlowStates.welcomeInstruction:
        return (
          <div>
            <PreInstruction onStartPressed  = {(averageNoise) => {this.start(averageNoise)}} 
                            texts = {this.text.Welcome}
                            
            />                               
          </div> 
        )

        case FlowStates.preInstruction:
          if(this.params.ballsEnabled == Bool.yes){
            //  DEMO:
            return (
              <div>
                
                <BallsPreInstruction onStartPressed  = { (result) => {this.handlePreInstructionDonePressed(result)} }
                                texts = {this.text.BallsPreInstruction}
 
                                ballsCount = {this.params.ballsCount}
                                movementDuration = {this.params.ballsPracticeDuration}
                                ballSelectedColor = {this.params.ballSelectedColor}
                                ballsSpeed = {this.params.ballsSpeed}
                                ballsCollisionEnabled = {this.params.ballsCollisionEnabled == Bool.yes}
                                ballsSpeedIsConstant = {this.params.ballsSpeedIsConstant == Bool.yes}

                                onDone = { (success) => {
                                  this.handlePreInstructionDonePressed(success)  
                                } 
                              }
                />                               
              </div> 
            )
          }
          else{
            return (
              <div>
                <PreInstruction2 onStartPressed  = { () => {this.handlePreInstructionDonePressed()} }
                                texts = {this.text.PreInstruction}
                                
                />                               
              </div> 
            )
          }


      case FlowStates.detectNoise:
        return (
          <div>
              <NoiseDetector  texts = {this.text.NoiseDetector} />                
          </div> 
        )
      

      
      case FlowStates.instruction:
        if(this.params.ballsEnabled == Bool.yes){
          return (
            <div>
              <BallsInstruction texts = {this.text.BallsInstruction} 
                                engine = {this.engine}/>
              <h1 style={{fontSize: "70px",position:"absolute",top:'50%',left:'50%'}} className = "countdown">{this.state.counter - this.params.recordingDuration}</h1>  
                                           
            </div> 
          )
        }
        else{
          return (
            <div>
              <Instruction texts = {this.text.Instruction}  />  
              <h1 className = "countdown">{this.state.counter - this.params.recordingDuration}</h1>
            </div>
          )
        }

      case FlowStates.record:
        if(this.params.ballsEnabled == Bool.yes){
          return (
            <div>
              <BallsRecord  texts = {this.text.BallsRecord}
                            engine = {this.engine} 
              
                            ballsCount = {this.params.ballsCount}
                            movementDuration = {this.params.recordingDuration}
                            ballRevealDuration = {this.params.ballRevealDuration}
                            ballSelectedColor = {this.params.ballSelectedColor}
                            ballsSpeed = {this.params.ballsSpeed}
                            ballsCollisionEnabled = {this.params.ballsCollisionEnabled == Bool.yes}
                            ballsRecordingDelay = {this.params.ballsRecordingDelay}
                            ballsSpeedIsConstant = {this.params.ballsSpeedIsConstant == Bool.yes}

                            onDone = { (success) =>{
                                console.log('balls run  result:',success)
                                this.correctBallSelected = success 
                                // move along:
                                this.setState({
                                  flowState: this.nextFlowState(),
                                })
                              }}
                            />                             
            </div> 
          )
        }
        else{
          return (
           
          <Record  texts = {this.text.Record} />
          )
        }

      case FlowStates.recordStop:
        if(this.params.ballsEnabled == Bool.yes){
          return (
            <BallsRecordStop  texts = {this.text.RecordStop} 
                              onStopPressed  = { () => {this.handleStopPressed()} }
                              
            />

          )
        }
        else{
          return (
              <RecordStop  texts = {this.text.RecordStop} 
            onStopPressed  = { () => {this.handleStopPressed()} }/>

          )
        }

      case FlowStates.workerIdQuestion:
        return <WorkerIdQuestion texts = {this.text.WorkerIdQuestion}  
                                  submitDelay ={null}  
                                  onCompletion = {(seconds)=>{this.handleWorkerIdDonePressed(seconds)}}/>

      case FlowStates.userDetails:
        return ( 
          <div >
            <UserDetails  rtl = {rtl}
                      texts = {this.text.UserDetails}
            onStartPressed = {(ageOption,languagesOption,genderOption) => this.handleUserDetailsDonePressed(ageOption,languagesOption,genderOption)}
                      showLanguage = {!(this.params.enforceLanguage==Bool.yes)}
                      submitDelay ={this.params.userDetailsSubmitDelay}  

            />     
          </div> 
        )

      case FlowStates.timeQuestion:
        return <RecordingTimeQuestion texts = {this.text.RecordingTimeQuestion}  
                                      submitDelay ={this.params.timeQuestionSubmitDelay}  
                                      onCompletion = {(seconds)=>{this.handleRecordingTimeDonePressed(seconds)}}/>
        
      case FlowStates.extraDataQuestion:
        return <ExtraDataQuestion   texts = {this.text.ExtraDataQuestion}
                                    submitDelay ={this.params.extraDataSubmitDelay}  
                                    onCompletion = {(extraDataStr)=>{this.handleExtraDataDonePressed(extraDataStr)}}/>  
      
      case FlowStates.multiAnswerQuestion:
        return  <Questionnaire  rtl = {rtl}
                                texts={this.text.Questionnaire} 
                                timeToAnswer = {this.params.multiAnswerTimeToAnswer}      
                                onDone = {(multiAnswerStr)=>{this.handleQuestionnaireDonePressed(multiAnswerStr)}}
                                askMeditationQuestion = {(this.params.askMeditationQuestion==Bool.yes)}/>

                            
      case FlowStates.userEmailQuestion:
        return <UserEmailQuestion texts = {this.text.UserEmailQuestion}  
                                  submitDelay ={this.params.userEmailQuestionSubmitDelay}  
                                  onCompletion = {(seconds)=>{this.handleUserEmailDonePressed(seconds)}}/>

      case FlowStates.touchTask:
        return  <TouchTask showCircles = {this.isMobile()} texts = {this.text.TouchTask} percent = {this.state.percent}/>          
      
      case FlowStates.done:
        return  <Done texts = {this.text.Done} 
                      showAssignmentId = {(this.params.ballsEnabled == Bool.yes)}
                      assignmentId={this.params.assignmentId}/>  

      default:
        return <p> App state is not recognized. </p>
    }
  }

  render() {

    let appClassName = this.params.language == 'heb' ?  'AppRTL' :  'AppLTR' 
    let direction = this.params.language == 'heb' ?  'rtl' :  'ltr' 

    return (
      <div dir = {direction} className={appClassName}>
          {platform.renderSubmitForm(this.params.assignmentId)}
          {this.renderAppState()}
      </div>
    );
  }

  // TBD: move componentWillUpdate into here:
  componentDidUpdate(){
    if(this.state.error){
      throw this.state.error
    }
  }
}

export default App;
