import React, { Component } from 'react';
import PreInstruction2 from './PreInstruction2';
import playground from '../playground'

const BALLS_RECORDING_DELAY = 3

class BallsPreInstruction extends Component {


  constructor(){
    super()
    this.state = {
        startedRun:false,
        ballsStoped:false,
    }
} 

componentDidMount(){
    this.setState({
        counter:BALLS_RECORDING_DELAY
    })
}

handleStartPressed(){
    //this.loadPlayground()
    this.setState({startedRun:true},()=> {this.loadPlayground()})
}

handleBallClicked(jackpot){
    //  not finished ? - do nothing
    if(this.state.startedRun && !this.state.ballsStoped){
        console.log('time hasnbt elapsed doing nothing')
        return 
    }

    if(jackpot){
        alert(this.props.texts.testRun.postRunSuccess)

    }
    else{
        alert(this.props.texts.testRun.postRunFail)
    }
    this.props.onDone(jackpot)
}

loadPlayground(){

    const startMovingIn = BALLS_RECORDING_DELAY * 1000;

    var element = document.getElementById("playgroundId")
    let pg = playground.airFriction( element,
                                    (jackpot)=>{this.handleBallClicked(jackpot)},
                                    this.props.ballsCount,
                                    this.props.ballSelectedColor,
                                    this.props.ballsSpeed,
                                    this.props.ballsCollisionEnabled,
                                    this.props.ballsSpeedIsConstant
                                    )
    
    this.timerInterval = setInterval(() => {
        if(this.state.counter > 0 ){
            this.setState({counter:this.state.counter-1})
        }
        console.log('counter:',this.state.counter)
        

    }, (1000) );  

    if(this.props.ballsSpeedIsConstant){
        this.movementInterval = setTimeout(() => {

            pg.changeDirection()
        }, (startMovingIn) );
    }
    else{
        this.movementInterval = setInterval(() => {

            pg.changeDirection()
        }, (startMovingIn) );
    }



    this.resetColor = setTimeout(() => {
        pg.changeColor()
    }, (startMovingIn) );


    this.stop = setTimeout(() => {
        clearInterval(this.movementInterval) 
        pg.stop()
        this.setState({ ballsStoped:true})
    }, ( (this.props.movementDuration * 1000) + startMovingIn) );
}

componentWillUnmount(){
    clearTimeout(this.resetColor)
    clearTimeout(this.stop)
    clearInterval(this.timerInterval)

}

render() {
    if(this.state.startedRun){
        
        return (
            <div id='playgroundId' >
                <h1 className="title" >{this.props.texts.testRun.title}</h1>
                {this.state.ballsStoped && <p style={{fontSize: "25px",position:"absolute",top:'40%',right:'20%'}}><b>{this.props.texts.testRun.postRunInstruction}</b></p>}
                { (this.state.counter > 0) && <h1 style={{fontSize: "70px",position:"absolute",top:'40%',left:'50%'}} >{this.state.counter}</h1>}

            </div>
            )
    }
    else{
        return (

            <div>
                <div>
                  <PreInstruction2 onStartPressed  = { () => {this.handleStartPressed()} }
                                  texts = {this.props.texts}
                                  
                  />                               
                </div> 

            </div>
        )
    }
        
}
}








export default BallsPreInstruction;


/*
render() {
  return (
    <div>
      <PreInstruction2 onStartPressed  = { () => {this.props.onStartPressed()} }
                      texts = {this.props.texts}
                      
      />                               
    </div> 
  )
}

*/