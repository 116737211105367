import React, { Component } from 'react';
import { Messages } from '../const'
class Preview extends Component {

    getPreviewText(){
        if(!this.props.previewText){
          return 'Please make sure you are in a quiet envirounment, and hold the phone close to your lips. You will be paid an ADDITIONAL BONUS payment according to the amount of acceptable items you provide. Pou have only one attempt, so please make sure you are ready before starting.'
        }
        else{
          return this.props.previewText
        }  
      }

    getIsMobileMessage() {
        if(!this.props.isMobile && this.props.mobileIsRequired){
            return Messages.notOnMobile
          }
          else{
            return ''
          } 
    }  
    
    render() {
      return (
        <div style= {{margin:'50px'}}>

             <h1 className="title" >{this.props.texts.title}</h1>
              <p> {this.props.texts.instruction}</p>

              <hr/>
              <p> {this.getIsMobileMessage()}</p>

        </div>
      )
    }
}

export default Preview;