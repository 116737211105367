//import '/rc-progress/assets/index.css';
import React, { Component } from 'react';
import { Line } from 'rc-progress';


class TouchTask extends Component {

  constructor() {
    super();
    this.state = {
      x: 50,
      y: 50
    }
  }

  changePosition() {

    function randomIntFromInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }

    this.setState({
      x: randomIntFromInterval(10, 60),
      y: randomIntFromInterval(20, 60),
    })
  }

  render() {
    console.log('this.props.percent:',this.props.percent)
    return (
      <div onClick={() => { this.changePosition() }}>
        <div style={{ margin: 10, width: '100%' }}>
          <Line strokeWidth="4" percent={this.props.percent} />
        </div>
        <h1 className="title" >{(this.props.showCircles ? this.props.texts.mobileTitle : this.props.texts.title)}</h1>

        <div style={{
          position: "absolute",
          left: this.state.x + '%',
          top: this.state.y + '%',
          color: "rgb(114, 5, 5)",
          fontSize: "50px"

        }}>
          {this.props.showCircles && <h1> o </h1>}
          
        </div>



      </div>
    )
  }
}

export default TouchTask;