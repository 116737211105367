import React, { Component } from 'react';

class Question extends Component {

    constructor(props) {
        super();
        this.state = {
            item1: '',
        }
    }

    handleContinueClicked(){
        clearTimeout(this.submitDelay) 

        let isCorrect = this.checkAnswer()
        this.setState({item1 : ''})
        this.props.onTimeElapsed(isCorrect)

    }

    componentDidMount(){
        this.submitDelay = setTimeout(() => {
            let isCorrect = this.checkAnswer()
            this.props.onTimeElapsed(isCorrect)
            this.setState({item1 : ''})

          }, (this.props.timeToAnswer * 1000) ); 
    }

    componentDidUpdate(lastProps){
        
        if(lastProps.index != this.props.index){
            clearTimeout(this.submitDelay) 

            this.submitDelay = setTimeout(() => {
                let isCorrect = this.checkAnswer()
                this.setState({item1 : ''})
                this.props.onTimeElapsed(isCorrect)


              }, (this.props.timeToAnswer * 1000) ); 
        }
    }

    checkAnswer(){
        console.log('checkAnswer:',this.state.item1)
        for (let index = 0; index < this.props.wlAr.length; index++) {
            const wlWord = this.props.wlAr[index];

            // change both to lower before search:
            let lowerItem1 = this.state.item1.toLowerCase();
            let lowerWlWord = wlWord.toLowerCase()

            if(lowerItem1.includes(lowerWlWord) ){
                return true
            }
            
        }
        return false
    }

    updateItem1Input(item1){
        this.setState({item1 : item1})
    }

    componentWillUnmount(){
        clearTimeout(this.submitDelay) 
    }
    
    render() {
        let answerInputClass = this.props.rtl  ?  'answerInput-RTL' :  'answerInput' 
        console.log('this.props.rtl :',this.props.rtl )
      return (
        <div >

            <h1 className="title" >{this.props.questionNumber} {this.props.index+1}:</h1>
            <h2 className = "instruction" >{this.props.title}</h2>
            <div className = "timeInputContainer">
              <textarea className = {answerInputClass} onChange={(event) => this.updateItem1Input(event.target.value)} type="textArea" placeholder={this.props.answerPlaceholder} maxLength="80" name="item1" value = {this.state.item1} />  
            </div>
            <h1 className="start-button" onClick={() => {this.handleContinueClicked()} }>{this.props.continueButton}</h1>

        </div>
      )
    }
} 

class Quiz extends Component {

    constructor(props) {
        super();
        this.numberOfQuestions = props.texts.questions.length
        this.state = {
            questionIndex: -1,
            correctAnswers: 0,
        }
    }

    componentDidMount(){
        console.log(this.props.texts.questions)
        
    }

    handleQuizDone(correctAnswers){

        // check success :
        let passed = true
        if(correctAnswers < this.props.passThreshold){
            passed = false
        }
        console.log('correctAnswers: ',correctAnswers,this.props.passThreshold)

        this.props.onDone(passed)
    }

    handleQuestionDone(isCorrect){
        let correctAnswers = isCorrect ? (this.state.correctAnswers+1) : this.state.correctAnswers 
        console.log('questionDone: index , isCorrect:',this.state.questionIndex,isCorrect)
        // last question was answered ?
        if(this.state.questionIndex + 1 == this.numberOfQuestions){
            this.handleQuizDone(correctAnswers)
        }
        else{
            this.setState({
                questionIndex: (this.state.questionIndex+1),
                correctAnswers: correctAnswers
            })
        }

    }

    handleStartPressed(){
        this.setState({
            questionIndex: 0,
        }) 
    }
    
    render() {

        if(this.state.questionIndex  < 0){
            return ( 
                <div >
                    <h1 className="title" >{this.props.texts.title}</h1>
                    <p> {this.props.texts.instruction}</p>
                    <h1 className="start-button" onClick={() => {this.handleStartPressed()} }>{this.props.texts.startButton}</h1>

                </div> 
            )
        }
        else{

            let questionTitle = this.props.texts.questions[this.state.questionIndex].title
            let wlAr = this.props.texts.questions[this.state.questionIndex].whiteList
            return ( 

                <Question   rtl = {this.props.rtl}
                            index = {this.state.questionIndex}
                            title={questionTitle}
                            answerPlaceholder = {this.props.texts.answerPlaceholder}
                            continueButton = {this.props.texts.continueButton}
                            questionNumber = {this.props.texts.questionNumber}
                            timeToAnswer = {this.props.timeToAnswer}
                            onTimeElapsed = { (isCorrect) => this.handleQuestionDone(isCorrect) }
                            wlAr = {wlAr}
                             >

                </Question>
        
            )
        }
      
    }
}

export default Quiz;