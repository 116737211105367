import React, { Component } from 'react';
const SECOND = 1000
class RecordingTimeQuestion extends Component {

  constructor(){
    super();
    this.state = {
      minutes : '',
      seconds : '',
    }
  }

  componentWillUnmount(){
    clearTimeout(this.submitDelayTimout)
  }

  componentDidMount(){

    console.log('RecordingTimeQuestion:submitDelay:',this.props.submitDelay)
    this.submitDelayTimout = setTimeout( () => {this.props.onCompletion(this.state.seconds) }, this.props.submitDelay * SECOND); 

    // Restricts input for the given textbox to the given inputFilter.
    function setInputFilter(textbox, inputFilter) {
      ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
        textbox.addEventListener(event, function() {
          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.value = this.oldValue;
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
          }
        });
      });
    }

    setInputFilter(document.getElementById("seconds"), function(value) {
      return /^\d*\.?\d*$/.test(value);
    });
  }

  updateSecondsInput(seconds){
    this.setState({seconds : seconds})
  }

  render() {
    return (
      <div>
          <h1 className="title" >{this.props.texts.title}</h1>
          <h2 className = "instruction" >{this.props.texts.instruction}</h2>
          <div className = "timeInputContainer">
              <input  id='seconds' 
                      className = "timeInput" 
                      onChange={(event) => this.updateSecondsInput(event.target.value)} 
                      type="text" 
                      placeholder={this.props.texts.secondsPlaceHolder} 
                      maxLength="3" 
                      name="seconds" 
                      value = {this.seconds}/>
          </div>
          <h1 className="start-button" onClick={() => {this.props.onCompletion(this.state.seconds)  }}>{this.props.texts.doneButton}</h1>

      </div>
    )
  }
}

export default RecordingTimeQuestion;
