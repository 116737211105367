import React, { Component } from 'react';
import Select from 'react-select';

class Welcome extends Component {

    constructor(props){
      super()


      // set Age options:
      this.options = []
      for (let index = 10; index < 90; index++) {
        var element = {
           value: index, 
           label: (index+'') 
        }
        this.options.push(element) 
      }

      // languages:
      let languages = props.texts.languageOptions
      this.languagesOptions = []
      for (let index = 0;index < languages.length; index++) {
        var element = {
           value: languages[index], 
           label: languages[index] 
        }
        this.languagesOptions.push(element) 
      }

      // levels:
      let levels = props.texts.languageLevelOptions
      this.levelsOptions = []
      for (let index = 0;index < levels.length; index++) {
        var element = {
            value: levels[index], 
            label: levels[index] 
        }
        this.levelsOptions.push(element) 
      }

      this.state = {
        gaveConsent:false,
        selectedOption: null,
        languages:[{
          selectedLanguagesOption:null,
          selectedLevelOption:null
        }]
      }
    }



    handleChange = (selectedOption) => {
      this.setState({ selectedOption });
    }

    handleLanguageChange = (selectedLanguagesOption,i) => {
        this.setState(state => {
          const languages = state.languages.map((item, j) => {
            if (j === i) {
              return {
                selectedLanguagesOption,
                selectedLevelOption:item.selectedLevelOption
              };
            } else {
              return item;
            }
          });
    
          return {
            languages,
          };
        });
    }

 
    handleLevelChange = (selectedLevelOption,i) => {

        this.setState(state => {
          const languages = state.languages.map((item, j) => {
            if (j === i) {
              return {
                
                selectedLevelOption,
                selectedLanguagesOption:item.selectedLanguagesOption
              };
            } else {
              return item;
            }
          });
    
          return {
            languages,
          };
        });
    }
  
    handleStartPressed(){     
      
      if(this.state.languages.length == 0 ){
        alert("To start, You must first select Languages in which you speak.")
        return 
      }
      else{
        let languagesValid =  false
        // check if all are null:
        for (let index = 0; index < this.state.languages.length; index++) {
          const language = this.state.languages[index];
          if(language.selectedLanguagesOption && language.selectedLevelOption){
            languagesValid = true;
          }
          else if (language.selectedLanguagesOption  && !language.selectedLevelOption){
            alert('you must select a level for each language.')
            return
          }
        }
        if(!languagesValid ){
          alert('you must select at least one language and the level in which you speak.')
          return
        }

      }

      this.props.onStartPressed(this.state.selectedOption,this.state.languages)

    }


    addLanguage() {
      console.log('laguages:',this.state.languages)
      // State change will cause component re-render
      this.setState({
        languages:this.state.languages.concat([
        {
          selectedLanguagesOption:null,
          selectedLevelOption:null
        }
        ])
      })
    }

    onRemoveLanguage = i => {
      this.setState(state => {
        const languages = state.languages.filter((item, j) => i !== j);
  
        return {
          languages,
        };
      });
    };

    showLanguageSection(languages){

        let addButtonClass = this.props.rtl  ?  'add-language-button-RTL' :  'add-language-button' 

        return (
          <div style={{
            paddingTop: '5px'
          }}>
              {this.props.texts.languageTitle}
              {
                  this.getLanguages(languages)
              }
              
              <h1 className={addButtonClass} onClick={() => {this.addLanguage()} }>{this.props.texts.languageAddButton}</h1>

          </div>
        )
       
    }

    getLanguages(languages){
      if(this.props.rtl){
        return (  
          languages.map((item,index) => (
            <div key={index}
              style={{
                marginLeft:'auto',
                paddingTop: '10px',
                display: 'flex',
                alignItems: 'flex-end',
                width: '100%',
                paddingRight:'0px'
              }}>

                <button style={{
                      marginRight:'10px',
                      marginTop:'3px',
                      height: '35px'
                    }}
                    type="button"
                    onClick={() => this.onRemoveLanguage(index)}
                  >
                    X
                </button>

                <Select className =  "language-select-RTL"
                  placeholder = {this.props.texts.languageLevelPlaceHolder}
                  value={item.selectedLevelOption}
                  onChange={(selectedOption) => this.handleLevelChange(selectedOption,index)}
                  options={this.levelsOptions}
                  isSearchable = {false}

                />

                <Select className =  "language-select-RTL"
                  placeholder = {this.props.texts.languagePlaceHolder}
                  value={item.selectedLanguagesOption}
                  onChange={(selectedOption) => this.handleLanguageChange(selectedOption,index)}
                  options={this.languagesOptions}
                  isSearchable = {false}

                />
              
            </div>
          ))
        )
      }
      else{ 
        return (

          
          languages.map((item,index) => (
            <div key={index}
              style={{
                marginRight:'auto',
                paddingTop: '10px',
                display: 'flex',
                alignItems: 'flex-begin',
                width: '100%',
                paddingRight:'0px'
              }}>
                <Select className =  "language-select"
                  placeholder = {this.props.texts.languagePlaceHolder}
                  value={item.selectedLanguagesOption}
                  onChange={(selectedOption) => this.handleLanguageChange(selectedOption,index)}
                  options={this.languagesOptions}
                  isSearchable = {false}

                />
                <Select className =  "language-select"
                  placeholder = {this.props.texts.languageLevelPlaceHolder}
                  value={item.selectedLevelOption}
                  onChange={(selectedOption) => this.handleLevelChange(selectedOption,index)}
                  options={this.levelsOptions}
                  isSearchable = {false}

                />
                <button style={{
                      marginLeft:'10px',
                      marginTop:'3px',
                      height: '35px'
                    }}
                    type="button"
                    onClick={() => this.onRemoveLanguage(index)}
                  >
                    X
                </button>
            </div>
          ))
        )
      }
    }
  
    render() {
      const { 
        gaveConsent,
        selectedOption,
        languages
      } = this.state;


      let ageSelectClass = this.props.rtl  ?  'age-select-RTL' :  'age-select-LTR' 


      return (
        <div>
            {
            
              <div>

                <h1 className="title" >{this.props.texts.title}</h1>
                  <p> {this.props.texts.instruction}</p>
          
                  {this.showLanguageSection(languages)}

                  <h1 className="start-button" onClick={() => {this.handleStartPressed()} }>{this.props.texts.startButton}</h1>
              </div>
            }
     
        </div>
      )
    }
}

export default Welcome;