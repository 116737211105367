
import { Messages } from './const'

export const ExceptionTypes = {
    IsPreviewException : 'IsPreviewException',
    StreamDidntArriveException : 'StreamDidntArriveException',
    NotMobileException : 'NotMobileException',
    GetUserMediaException:'GetUserMediaException',
    MicNotAllowedException:'NotAllowedError',
    GetAudioContextException:'GetAudioContextException',
    CreateAudioNodeException:'CreateAudioNodeException',
    WorkerAlreadyParticipatedException:'WorkerAlreadyParticipatedException',
    WorkerLanguageRequirementException:'WorkerLanguageRequirementException',
    GetServerDataException:'GetServerDataException',
    NoiseDetectionFailedException:'NoiseDetectionFailed',
    SimpleException:'SimpleException',
}


export const IsPreviewException = function(isMobile,mobileIsRequired,texts) {
    this.isMobile = isMobile
    this.mobileIsRequired = mobileIsRequired
    this.type = ExceptionTypes.IsPreviewException;
    this.texts = texts
 }

export const NotMobileException = function(message) {

    this.message = Messages.notOnMobile;
    // override message ?
    if(message){
        this.message = message;
    }
    this.type = ExceptionTypes.NotMobileException;
 }

 export const GetUserMediaException = function(errObj) {
   
    this.type = errObj.name;
    if(this.type == ExceptionTypes.MicNotAllowedException){
        this.message = Messages.RequireMicEnable;
    }
    else{
        this.message = Messages.BrowserNotCompatible;
    }
 }

 export const GetAudioContextException = function(message) {

    this.message = Messages.BrowserNotCompatible;
    // override message ?
    if(message){
        this.message = message;
    }
    this.type = ExceptionTypes.GetAudioContextException;
 }

 
 export const GetSignedUrlException = function(message) {

    this.message = Messages.LoadingError;
    // override message ?
    if(message){
        this.message = message;
    }
    this.type = ExceptionTypes.GetSignedUrlException;
 }

 export const CreateAudioNodeException = function(message) {

    this.message = Messages.BrowserNotCompatible;
    // override message ?
    if(message){
        this.message = message;
    }
    this.type = ExceptionTypes.CreateAudioNodeException;
 }
 export const WorkerAlreadyParticipatedException = function(message) {

    this.message = Messages.WorkerAlreadyParticipated;
    // override message ?
    if(message){
        this.message = message;
    }
    this.type = ExceptionTypes.WorkerAlreadyParticipatedException;
 }

 export const WorkerLanguageRequirementException = function(message) {

    this.message = Messages.WorkerLanguageRequirement;
    // override message ?
    if(message){
        this.message = message;
    }
    this.type = ExceptionTypes.WorkerLanguageRequirementException;
 }

 export const GetServerDataException = function(message) {

    this.message = Messages.LoadingError;
    // override message ?
    if(message){
        this.message = message;
    }
    this.type = ExceptionTypes.GetServerDataException;
 }

 
 export const EncoderInitException = function(message) {

    this.message = Messages.EncoderInitFailed;
    // override message ?
    if(message){
        this.message = message;
    }
    this.type = ExceptionTypes.EncoderInitException;
 }
 
 export const NoiseDetectionFailedException = function(message) {

    this.message = Messages.NoiseDetectionFailed;
    // override message ?
    if(message){
        this.message = message;
    }
    this.type = ExceptionTypes.NoiseDetectionFailedException;
 }

 export const SimpleException = function(message,type,workerId) {

    this.message = 'Error Had Occured.';
    // override message ?
    if(message){
        this.message = message;
    }
    this.type = type;
    this.workerId = workerId
 }